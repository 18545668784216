import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "#hooks/lang.hook";
import { hideAccept, setAccept } from "#features/acceptSlice";
import { Button } from "#formComponents";

export function Accept() {
  const dispatch = useDispatch();
  const currentAccept = useSelector((state) => state.accept.message);
  const currentAcceptVisible = useSelector((state) => state.accept.visible);
  const translation = useTranslation([
    { group: "alert", name: "attention" },
    { group: "alert", name: "yes" },
    { group: "alert", name: "no" },
  ]);

  const hideAcceptHandle = () => {
    dispatch(hideAccept());
  };

  const acceptHandle = () => {
    dispatch(setAccept(true));
  };

  const rejectHandle = () => {
    dispatch(setAccept(false));
  };

  const keyDownYesHandle = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      dispatch(setAccept(true));
    }
  };

  if (!currentAcceptVisible) return <></>;

  return (
    <div
      className="absolute w-full min-h-[calc(100svh)] flex items-center justify-center z-50"
      onClick={hideAcceptHandle}
      onKeyDown={keyDownYesHandle}
    >
      <div className="absolute flex w-[75%] h-[50%] min-h-[20rem] bg-color01 opacity-90 rounded-lg shadow-lg shadow-color01/50" />
      <div className="absolute flex flex-col w-[75%] h-[50%] min-h-[20rem] items-center justify-around">
        <div className="flex flex-1">
          <span className="text-color05 text-4xl font-bold italic mt-12 without-selection cursor-default">
            {translation.attention}
          </span>
        </div>
        <div className="flex flex-1">
          <span className="text-color05 text-2xl font-semibold mt-4 without-selection cursor-default px-4">
            {currentAccept}
          </span>
        </div>
        <div className="flex mb-12 w-full gap-6 justify-center">
          <Button onClickHandle={acceptHandle} label={translation.yes} />
          <Button onClickHandle={rejectHandle} label={translation.no} />
        </div>
      </div>
    </div>
  );
}
