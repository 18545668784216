import { useState, useCallback, useEffect } from "react";
import { PicClose } from "#svg";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { useHttp } from "#hooks/http.hook";
import { Spinner, Button } from "#formComponents";

export function SelectPersonPosition({ applyData, hideForm }) {
  const [listPerson, setListPerson] = useState();
  const [listPosition, setListPosition] = useState();
  const [currentPerson, setCurrentPerson] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const [personFilter, setPersonFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("");

  let filteredPerson = [];
  let filteredPosition = [];

  const { loading, request } = useHttp();

  const readData = useCallback(async () => {
    try {
      const resultOerson = await request("/people", "GET", "", {});
      setListPerson(!resultOerson.records ? [] : resultOerson.records);
      setCurrentPerson(
        !resultOerson.records
          ? undefined
          : {
              id: resultOerson.records[0].id,
              code: resultOerson.records[0].code,
              name: resultOerson.records[0].name,
            }
      );
      const resultJob = await request("/job", "GET", "", {});
      setListPosition(!resultJob.records ? [] : resultJob.records);
      setCurrentPosition(
        !resultJob.records
          ? undefined
          : {
              id: resultJob.records[0].id,
              code: resultJob.records[0].code,
              name: resultJob.records[0].name,
            }
      );
    } catch (e) {}
  }, [request]);

  const selectHandle = () => {
    hideForm();
    applyData({
      personId: currentPerson.code,
      personName: currentPerson.name,
      jobId: currentPosition.code,
      jobName: currentPosition.name,
    });
  };

  const closeHandle = () => {
    hideForm();
  };

  const onChangePersonFilterHendle = (e) => {
    setPersonFilter(e.target.value);
  };

  const onChangePositionFilterHendle = (e) => {
    setPositionFilter(e.target.value);
  };

  const translation = useTranslation([
    { group: "menu", name: "personal" },
    { group: "menu", name: "job" },
    { group: "common", name: "select" },
  ]);

  useEffect(() => {
    readData();
  }, []);

  if (personFilter === "") {
    filteredPerson = listPerson;
  } else {
    filteredPerson = listPerson.filter((obj) =>
      obj.name.toLowerCase().includes(personFilter.toLowerCase())
    );
  }

  if (positionFilter === "") {
    filteredPosition = listPosition;
  } else {
    filteredPosition = listPosition.filter((obj) =>
      obj.name.toLowerCase().includes(positionFilter.toLowerCase())
    );
  }

  return (
    <div className="absolute w-full min-h-[calc(100svh)] flex justify-center z-50">
      <div className="absolute flex w-[75%] h-[calc(90svh)] bg-color02 opacity-90 rounded-lg shadow-lg shadow-color01/50" />
      <div className="absolute flex flex-col w-[75%] h-[calc(90svh)] items-center p-8">
        {loading === true && (
          <div className="absolute flex w-full mt-14 justify-center">
            <Spinner />
          </div>
        )}
        <div className="flex w-full justify-between">
          <Button
            onClickHandle={selectHandle}
            label={translation.select}
            size="small"
          />
          {currentPerson && currentPosition && (
            <span className="without-selection text-md font-bold text-color03">{`${currentPerson?.name} / ${currentPosition?.name}`}</span>
          )}
          <div
            onClick={closeHandle}
            className="fill-color01 mt-[-0.1rem] h-6 w-6 text-colorRed transition-{rotate} duration-150 hover:rotate-180"
          >
            <PicClose />
          </div>
        </div>
        <div className="flex w-full gap-4 my-4">
          <input
            placeholder={translation.personal}
            type="text"
            value={personFilter}
            className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
            onChange={onChangePersonFilterHendle}
          />
          <input
            placeholder={translation.job}
            type="text"
            value={positionFilter}
            className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
            onChange={onChangePositionFilterHendle}
          />
        </div>
        <div className="flex w-full gap-4">
          <div className="flex w-1/2 max-h-[calc(90svh-10rem)] overflow-y-auto">
            <AnimatePresence>
              <motion.table className="w-full text-left text-sm font-light">
                <tbody>
                  {filteredPerson &&
                    filteredPerson.map((row, id) => {
                      return (
                        <motion.tr
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{
                            duration: 0.05,
                            delay: 0.05 * id,
                          }}
                          className={"transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-default".concat(
                            currentPerson.id === row.id ? " bg-color03" : ""
                          )}
                          key={id}
                          id={id}
                          onClick={() =>
                            setCurrentPerson({
                              id: row.id,
                              code: row.code,
                              name: row.name,
                            })
                          }
                        >
                          <td className="without-selection whitespace-nowrap font-medium px-2 rounded-md">
                            {row.name}
                          </td>
                        </motion.tr>
                      );
                    })}
                </tbody>
              </motion.table>
            </AnimatePresence>
          </div>
          <div className="flex w-1/2 max-h-[calc(90svh-10rem)] overflow-y-auto">
            <AnimatePresence>
              <motion.table className="w-full text-left text-sm font-light">
                <tbody>
                  {filteredPosition &&
                    filteredPosition.map((row, id) => {
                      return (
                        <motion.tr
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{
                            duration: 0.05,
                            delay: 0.05 * id,
                          }}
                          className={"w-full transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-default".concat(
                            currentPosition.id === row.id ? " bg-color03" : ""
                          )}
                          key={id}
                          id={id}
                          onClick={() =>
                            setCurrentPosition({
                              id: row.id,
                              code: row.code,
                              name: row.name,
                            })
                          }
                        >
                          <td className="without-selection whitespace-nowrap font-medium px-2 rounded-md">
                            {row.name}
                          </td>
                        </motion.tr>
                      );
                    })}
                </tbody>
              </motion.table>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}
