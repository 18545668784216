export const classificatorSettings = (type) => {
  const settings = {
    people: {
      classificatorName: "people",
      tableName: "people",
      editInList: true,
      columns: [
        {
          id: "name",
          name: "name",
          label: "name",
          type: "string",
          required: true,
        },
        { id: "code", name: "code", label: "peopleCode", type: "string" },
        {
          id: "id_company",
          name: "id_company",
          label: "id_company",
          type: "string",
        },
      ],
    },
    job: {
      classificatorName: "job",
      tableName: "job",
      editInList: true,
      columns: [
        {
          id: "name",
          name: "name",
          label: "name",
          type: "string",
          required: true,
        },
        { id: "code", name: "code", label: "jobCode", type: "string" },
        {
          id: "id_company",
          name: "id_company",
          label: "id_company",
          type: "string",
        },
      ],
    },
    daytype: {
      classificatorName: "daytype",
      tableName: "daytype",
      editInList: true,
      columns: [
        {
          id: "id",
          name: "id",
          label: "dayTypeId",
          type: "string",
          isAdditional: true,
        },
        {
          id: "ordering",
          name: "ordering",
          label: "dayTypeOrder",
          type: "number",
        },
        {
          id: "name",
          name: "name",
          label: "name",
          type: "string",
          isAdditional: true,
        },
        {
          id: "short_name",
          name: "short_name",
          label: "dayTypeShortName",
          type: "string",
        },
        {
          id: "color",
          name: "color",
          label: "dayTypeColor",
          type: "string",
        },
        {
          id: "id_company",
          name: "id_company",
          label: "id_company",
          type: "string",
        },
      ],
    },
    users: {
      classificatorName: "users",
      tableName: "users",
      editInList: true,
      columns: [
        {
          id: "id",
          name: "id",
          label: "id",
          type: "string",
          isAdditional: true,
        },
        {
          id: "name",
          name: "name",
          label: "name",
          type: "string",
        },
        {
          id: "email",
          name: "email",
          label: "usersEmail",
          type: "string",
        },
        {
          id: "lang",
          name: "lang",
          label: "usersLang",
          type: "string",
        },
        {
          id: "id_company",
          name: "id_company",
          label: "id_company",
          type: "string",
        },
        {
          id: "is_salary_approve",
          name: "is_salary_approve",
          label: "usersIs_salary_approve",
          type: "boolean",
        },
        {
          id: "is_tabel",
          name: "is_tabel",
          label: "usersIs_tabel",
          type: "boolean",
        },
        {
          id: "is_tabel_add",
          name: "is_tabel_add",
          label: "usersIs_tabel_add",
          type: "boolean",
        },
        {
          id: "disabled",
          name: "disabled",
          label: "usersDisabled",
          type: "boolean",
        },
        {
          id: "permissions",
          name: "permissions",
          label: "usersPermissions",
          type: "string",
        },
      ],
    },
  };

  return settings[type];
};
