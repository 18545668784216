import { motion } from "framer-motion";

export function SlideMenu({ show, listButtons, selectHandle }) {
  const newHeight = 100 + listButtons.length * 45;
  const onClickHandle = (e) => {
    selectHandle(
      e.target.id ||
        e.target.parentElement.id ||
        e.target.parentElement.parentElement.id
    );
  };

  return show ? (
    <div className="absolute mx-12 min-h-[calc(100svh)] top-0 left-0">
      {show && (
        <motion.div
          initial={{ height: 0 }}
          animate={{
            height: newHeight,
          }}
          exit={{
            height: 0,
            transition: { type: "spring", delay: 0.7, duration: 0.1 },
          }}
          transition={{
            type: "spring",
            staggerChildren: 0.2,
            staggerDirection: 1,
          }}
          className="absolute top-0 -left-10 sm:-left-9 md:-left-4 w-[17rem] bg-color01 opacity-60 rounded-b-lg shadow-lg shadow-color01/50"
        />
      )}
      {show && (
        <motion.ul
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.4,
            duration: 0.2,
          }}
          className="absolute flex flex-col gap-5 -left-10 sm:-left-9 md:-left-4 w-[17rem] mt-[5rem] mx-5 items-start"
        >
          {listButtons.map((row) => (
            <li
              key={row.id}
              id={row.id}
              className="flex gap-2 group items-center cursor-pointer"
              onClick={onClickHandle}
            >
              <div className="text-color05 cursor-pointer group-hover:text-color04 w-6 h-6">
                {row.pic}
              </div>
              <span className="font-bold text-lg text-color05 cursor-pointer group-hover:text-color04 without-selection">
                {row.name}
              </span>
            </li>
          ))}
        </motion.ul>
      )}
    </div>
  ) : (
    <></>
  );
}
