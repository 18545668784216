import { useRef, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useHttp } from "#hooks/http.hook";
import { setUser } from "#features/userSlice";
import { setLang } from "#features/langSlice";
import { showAlert } from "#features/alertSlice";
import { Spinner, Button, Alert, InputGroup } from "#formComponents";
// import { normalizeLogin } from "#common/verification";
import { useTranslation } from "#hooks/lang.hook";
import { PicClose } from "#svg";

export function Authentificator() {
  const [showBlock, setShowBlock] = useState(false);
  const translation = useTranslation([
    { group: "auth", name: "user" },
    { group: "auth", name: "password" },
    { group: "auth", name: "reset" },
    { group: "auth", name: "start" },
    { group: "auth", name: "create" },
    { group: "auth", name: "passwordEnter" },
    { group: "auth", name: "passwordFormat" },
    { group: "auth", name: "serverErr" },
    { group: "auth", name: "passwordErr" },
    { group: "auth", name: "resetSent" },
    { group: "auth", name: "google" },
    { group: "auth", name: "invitationSent" },
  ]);

  const userEmail = useRef();
  const userPassword = useRef();
  const { loading, request } = useHttp();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAlert = useCallback(
    (textAlert) => {
      dispatch(showAlert(textAlert));
    },
    [dispatch]
  );

  const verifyPassword = useCallback(
    async (curEmail, curPassword) => {
      try {
        const strRes = await request(
          "/users/verify",
          "POST",
          { email: curEmail, password: curPassword },
          {}
        );

        if (strRes?.error?.code === "ECONNABORTED") {
          getAlert(translation.serverErr);
          return;
        }

        if (!strRes?.token) {
          getAlert(translation.passwordErr);
          return;
        }
        window.localStorage.setItem("token", strRes?.token);
        window.localStorage.setItem("userEmail", curEmail);
        if (strRes.data.lang) {
          window.localStorage.setItem("lang", strRes.data.lang);
          dispatch(setLang(strRes.data.lang));
        }
        dispatch(
          setUser({
            email: curEmail,
            id: strRes.data.id,
            name: strRes.data.name,
            permissions: strRes.data.permissions,
            isSalaryApprove: strRes.data.isSalaryApprove,
            isTabel: strRes.data.isTabel,
          })
        );
        if (strRes.data.isSalaryApprove) {
          navigate("/payroll");
        } else {
          navigate("/timesheet");
        }
      } catch (e) {}
    },
    [request]
  );

  const checkToken = async () => {
    const strRes = await request("/users/checkToken", "POST", {}, {});
    if (strRes?.data) {
      if (strRes.data.lang) {
        window.localStorage.setItem("lang", strRes.data.lang);
        dispatch(setLang(strRes.data.lang));
      }
      dispatch(
        setUser({
          email: strRes.email,
          id: strRes.data.id,
          name: strRes.data.name,
          permissions: strRes.data.permissions,
          isSalaryApprove: strRes.data.isSalaryApprove,
          isTabel: strRes.data.isTabel,
        })
      );
      if (strRes.data.isSalaryApprove) {
        navigate("/payroll");
      } else {
        navigate("/timesheet");
      }
    }
    setShowBlock(true);
  };

  const verifyHandle = async () => {
    const curEmail = userEmail.current.value.trim();
    if (curEmail === "") {
      getAlert(translation.passwordEnter);
      return;
    }
    // if (check_email(curEmail) === false) {
    //   getAlert(translation.passwordFormat);
    //   return;
    // }
    const curPassword = userPassword.current.value;
    verifyPassword(curEmail, curPassword);
  };

  const keyDownPasswordHandle = (e) => {
    if (e.key === "Enter") {
      verifyHandle();
    }
  };

  const keyDownEmailHandle = (e) => {
    if (e.key === "Enter") {
      userPassword.current.focus();
    }
  };

  const closeHandle = (element) => {
    navigate("/");
  };

  const onChangeFieldHandle = () => {};

  useEffect(() => {
    const curToken = window.localStorage.getItem("token");
    if (curToken) checkToken();
    else setShowBlock(true);
  }, []);

  useEffect(() => {
    if (!showBlock) return;
    if (!userEmail.current.value)
      userEmail.current.value = window.localStorage.getItem("userEmail");
    if (userEmail.current.value) userPassword.current.focus();
  }, [showBlock]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col w-full min-h-[calc(100svh)] relative bg-color04 items-center justify-center"
      >
        <Alert />
        <div className="flex flex-col w-[400px] px-10 pb-10 pt-5 bg-color02 relative rounded-md shadow-lg">
          {loading === true && (
            <div className="absolute flex mt-14 w-[300px] items-center justify-center">
              <Spinner />
            </div>
          )}
          <div className={"flex flex justify-end"}>
            <div
              onClick={closeHandle}
              className="fill-color01 h-6 w-6 text-colorRed transition-{rotate} duration-150 hover:rotate-180"
            >
              <PicClose />
            </div>
          </div>
          {showBlock && (
            <>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.1,
                  delay: 0.1,
                }}
                key={"userEmail"}
              >
                <InputGroup
                  groupKey={"userEmail"}
                  label={translation.user}
                  type={"text"}
                  placeholder={"Login"}
                  refInput={userEmail}
                  onChangeHandle={keyDownEmailHandle}
                  onKeyDown={keyDownEmailHandle}
                />
              </motion.div>

              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.1,
                  delay: 0.1 * 2,
                }}
                key={"password"}
              >
                <InputGroup
                  groupKey={"password"}
                  label={translation.password}
                  type={"password"}
                  placeholder={translation.passwordEnter}
                  refInput={userPassword}
                  onChangeHandle={onChangeFieldHandle}
                  onKeyDown={keyDownPasswordHandle}
                />
              </motion.div>

              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  duration: 0.1,
                  delay: 0.1 * 3,
                }}
                key={"controlBar"}
                className="flex flex-col gap-3 mt-5"
              >
                <Button
                  onClickHandle={verifyHandle}
                  label={translation.start}
                />
              </motion.div>
            </>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
