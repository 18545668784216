import { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { useHttp } from "#hooks/http.hook";
import { Spinner } from "#formComponents";

const DATESELECTOR = "DATESELECTOR24";
export let WORK_DAY_DEFAULT = {};

export function TypeElement({
  currentType,
  rowType,
  customHour,
  selectTypeHandle,
}) {
  const [hovered, setHovered] = useState(false);

  return (
    <div key={rowType.id} className="cursor-pointer">
      <motion.div
        key={`tooltip${rowType.id}`}
        initial={{
          display: "none",
          opacity: 0,
          marginTop: "-50px",
          height: 0,
        }}
        animate={{
          display: hovered ? "block" : "none",
          opacity: hovered ? 1 : 0,
          marginTop: hovered ? "-34px" : "-50px",
          height: hovered ? "auto" : 0,
          transition: 0.01,
        }}
        className="absolute bg-white rounded-lg z-50 px-2 py-1"
      >
        <span>{rowType.dateselector ? rowType.id : rowType.tooltip}</span>
      </motion.div>
      <motion.div
        key={`main${rowType.id}`}
        onClick={() => selectTypeHandle(rowType)}
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
        whileHover={{ scale: 1.1 }}
        className={`flex justify-center items-center w-12 h-9 m-0.5 font-medium rounded-lg shadow-lg shadow-color01/30 hover:brightness-75`.concat(
          rowType.id === currentType.id ? " border-2" : ""
        )}
        style={{ backgroundColor: rowType.color }}
      >
        <span className="without-selection">
          {!rowType.dateselector || customHour === ""
            ? rowType.label
            : customHour}
        </span>
      </motion.div>
    </div>
  );
}

export function SelectWorkDayType({ currentType, setCurrentType, idTabel }) {
  const [listWorkDayType, setListWorkDayType] = useState([]);
  const [customHour, setCustomHour] = useState(0);
  const [showHour, setShowHour] = useState(false);
  const { loading, request } = useHttp();

  const readData = useCallback(async () => {
    try {
      const result = await request("/daytype", "GET", { idTabel }, {});
      setListWorkDayType(
        !result.records
          ? []
          : result.records.map((row) => ({
              id:
                row.name === DATESELECTOR
                  ? row.short_name
                  : row.short_name || row.name,
              label: row.short_name,
              color: row.color,
              tooltip: row.name,
              dateselector: row.name === DATESELECTOR,
            }))
      );
      setShowHour(result.records.find((row) => row.name === DATESELECTOR));
    } catch (e) {}
  }, [request]);

  const onChangeHandle = (e) => {
    let valNum = e.target.value;
    valNum = valNum.replace(/[^0-9.]/g, "");
    valNum = valNum.replace(/,/g, ".");
    if (valNum > 24) valNum = 24;
    if (valNum.length > 5) {
      valNum = valNum.slice(0, 5);
    }

    setListWorkDayType((prev) =>
      prev.map((oldType) =>
        oldType.dateselector
          ? { ...oldType, id: valNum, label: valNum }
          : oldType
      )
    );
    setCustomHour(valNum);
  };

  const selectTypeHandle = (type) => {
    setCurrentType(type);
  };

  useEffect(() => {
    const isDS = listWorkDayType.find((row) => row.dateselector);
    if (isDS) {
      WORK_DAY_DEFAULT = isDS;
      setCurrentType(isDS);
      setCustomHour(isDS.id);
    } else {
      if (listWorkDayType.length) setCurrentType(listWorkDayType[0]);
    }
  }, [listWorkDayType]);

  useEffect(() => {
    readData();
  }, []);

  return (
    <div className="flex flex-wrap items-center gap-3 mx-2">
      {loading === true && (
        <div className="absolute flex w-full mt-14 justify-center">
          <Spinner />
        </div>
      )}
      {listWorkDayType?.length
        ? listWorkDayType.map((rowType) => {
            return (
              <TypeElement
                key={rowType.id}
                currentType={currentType}
                rowType={rowType}
                customHour={customHour}
                selectTypeHandle={selectTypeHandle}
              />
            );
          })
        : null}
      {showHour ? (
        <input
          type="number"
          value={customHour}
          onChange={onChangeHandle}
          className="w-17 h-9 border border-color02 text-color01 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
          required
          min="1"
          max="24"
        />
      ) : null}
    </div>
  );
}
