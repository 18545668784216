// import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, LangMenu } from "#formComponents"; // Cookies,
import { useTranslation } from "#hooks/lang.hook";

export function Home() {
  const navigate = useNavigate();

  // const [cookieUsing, setCookieUsing] = useState(
  //   window.localStorage.getItem("use_cookie") || undefined
  // );
  const translation = useTranslation([
    { group: "home", name: "caption" },
    { group: "home", name: "start" },
  ]);

  const clientHandle = () => {
    setTimeout(() => {
      navigate("/auth");
    }, 600);
  };

  // useEffect(() => {
  //   if (
  //     cookieUsing !== undefined &&
  //     window.localStorage.getItem("use_cookie") !== cookieUsing
  //   ) {
  //     window.localStorage.setItem(
  //       "use_cookie",
  //       cookieUsing && cookieUsing === "true"
  //     );
  //   }
  // }, [cookieUsing]);

  return (
    <div className={`min-h-[calc(100svh)] bg-color04`}>
      <LangMenu />
      {/* {cookieUsing === undefined && (
        <div className="absolute bottom-0">
          <Cookies setCookieUsing={setCookieUsing} />
        </div>
      )} */}
      <div className="flex flex-col items-center justify-center min-h-[calc(100svh)]">
        <span className="without-selection text-5xl font-large italic mb-5 text-color01 cursor-default">
          {translation?.caption}
        </span>
        <div className="flex items-center justify-items-center flex-wrap">
          <Button onClickHandle={clientHandle} label={translation?.start} />
        </div>
      </div>
    </div>
  );
}
