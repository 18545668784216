import { motion } from "framer-motion";

export function Button({
  label,
  icon,
  onClickHandle,
  size,
  color,
  colorHover,
  selected,
}) {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      type="button"
      className={"text-color01 focus:ring-4 focus:outline-none focus:ring-color04 shadow-lg shadow-color01/50 font-medium rounded-lg text-sm text-center "
        .concat(size === "small" ? "px-3 py-1" : "px-5 py-2.5")
        .concat(color ? ` bg-${color}` : " bg-color03")
        .concat(colorHover ? ` hover:bg-${colorHover}` : " hover:bg-color04")
        .concat(selected ? ` border-4` : "")}
      onClick={onClickHandle}
    >
      {icon && icon}
      {label && <span className="text-xl without-selection">{label}</span>}
    </motion.button>
  );
}
