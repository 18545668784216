import { useState } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion } from "framer-motion";
import { PicSearch, PicClose } from "#svg";
import { Button } from "#formComponents";

export function SearchGroup({ textFilter, setTextFilter }) {
  const [showSearch, setShowSearch] = useState(false);
  const translation = useTranslation([{ group: "common", name: "search" }]);

  const showSearcheHandle = () => {
    setShowSearch(true);
  };

  const closeSearcheHandle = () => {
    setShowSearch(false);
    setTextFilter("");
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  return (
    <>
      {showSearch ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="relative flex flex-grow items-center"
        >
          <input
            type="text"
            value={textFilter}
            className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
            onChange={onChangeSearchHendle}
            placeholder={translation.search}
          />
          <button
            onClick={closeSearcheHandle}
            className="absolute right-2 text-color01 p-1 z-20 h-7 w-7"
          >
            <PicClose className="text-color05" />
          </button>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          whileTap={{ scale: 0.95 }}
        >
          <Button
            onClickHandle={showSearcheHandle}
            icon={<PicSearch />}
            size="small"
          />
        </motion.div>
      )}
    </>
  );
}
