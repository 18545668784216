import { useState, useEffect } from "react";
import { motion } from "framer-motion";

export function SelectWorkDayType({
  listWorkDayType,
  setListWorkDayType,
  currentType,
  setCurrentType,
}) {
  const [customHour, setCustomHour] = useState("8");

  const onChangeHandle = (e) => {
    let valNum = e.target.value;
    valNum = valNum.replace(/[^0-9.]/g, "");
    valNum = valNum.replace(/,/g, ".");
    // if (valNum === "") valNum = 1;
    if (valNum > 24) valNum = 24;
    if (valNum.length > 5) {
      valNum = valNum.slice(0, 5);
    }

    setCustomHour(valNum);
  };

  const selectTypeHandle = (type) => {
    setCurrentType(type);
  };

  useEffect(() => {
    if (customHour === "" || isNaN(customHour)) {
      return;
    }

    const currentNumeric = listWorkDayType.find(
      (type) => isNaN(type.id) === false
    );
    const newDayType = {
      ...currentNumeric,
      id: customHour,
      label: customHour.toString(),
    };
    setCurrentType(newDayType);
    setListWorkDayType((prev) =>
      prev.map((type) => (isNaN(type.id) ? type : newDayType))
    );
  }, [customHour]);

  return (
    <div className="flex flex-wrap items-center gap-3">
      {listWorkDayType.map((rowType) => (
        <motion.div
          key={rowType.id}
          onClick={() => selectTypeHandle(rowType)}
          whileHover={{ scale: 1.1 }}
          className={`flex justify-center items-center w-12 h-9 m-0.5 font-medium cursor-pointer rounded-lg shadow-lg shadow-color01/30 ${rowType.color} hover:brightness-75`.concat(
            rowType.id === currentType.id ? " border-2" : ""
          )}
        >
          <span className="without-selection">
            {isNaN(rowType.id) || customHour === ""
              ? rowType.label
              : customHour}
          </span>
        </motion.div>
      ))}
      <input
        type="number"
        value={customHour}
        onChange={onChangeHandle}
        // onKeyDown={onKeyDown}
        className="w-17 h-9 border border-color02 text-color01 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
        required
        min="1"
        max="24"
      />
    </div>
  );
}
