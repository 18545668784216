import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const useHttp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response?.status === 403) {
        window.localStorage.removeItem("token");
        navigate("/");
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      const host = process.env.REACT_APP_HOST || "https://worktiming.nl";
      const port = process.env.REACT_APP_APIPORT || "5000";

      // const timeBegin = new Date();

      setLoading(true);
      let token = window.localStorage.getItem("token");

      if (!token) {
        token = "No token";
      }
      //console.log("Axios:", method, host + ":" + port + "/api" + url, body);
      try {
        return await axios({
          method: method,
          url: host + ":" + port + "/api" + url,
          headers: {
            "Access-Control-Allow-Origin": "https://supportteam.ua:5000",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          data: method === "GET" ? null : body,
          params: method === "GET" ? body : null,
          timeout: 2000,
        })
          .then(function (response) {
            // console.log(
            //   "Axios metric:",
            //   url,
            //   response.status,
            //   new Date() - timeBegin
            // );
            if (response.status === 200) {
              return response.data;
            } else {
              throw new Error(response?.data || "Something wrong");
            }
          })
          .catch(function (error) {
            console.log(error);
            setError(error.message);
            return { error };
          });
      } catch (e) {
        console.log(e);
        setError(e.message);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { loading, request, error };
};
