import { useState, useEffect } from "react";
import { PicHamburger } from "#svg";

export function SmallMenu({ menuData, selectMenuHandle }) {
  const [currentElement, setCurrentElement] = useState(undefined);
  const [opened, setOpened] = useState(false);

  const selectHandle = (e) => {
    const key = e.target.id || e.target.parentElement.id;
    setCurrentElement(key);
    selectMenuHandle(key);
    setOpened(false);
  };

  const openHandle = (e) => {
    setOpened((prev) => !prev);
  };

  const getName = (id) => {
    const rowMenu = menuData.find((row) => row.id === id);
    return rowMenu ? rowMenu.name : "";
  };

  useEffect(() => {
    if (menuData) setCurrentElement(menuData[0].id);
  }, [menuData]);

  return (
    <div className="flex flex-col relative">
      <div
        onClick={openHandle}
        className="flex items-center justify-left h-12 px-4 rounded-lg text-color01 hover:bg-gradient-to-r from-color03 to-color04 cursor-pointer"
      >
        <PicHamburger />

        <span className="without-selection min-w-[140px] font-semibold text-left mx-4">
          {getName(currentElement)}
        </span>
      </div>
      {opened && (
        <ul
          className="absolute z-50 flex-col bg-color05 rounded-md shadow-sm flex flex-wrap px-3 py-4 mt-12 shadow-lg shadow-color01/50"
          role="group"
        >
          {menuData.map((row) => (
            <li key={row.id} className="flex-1 my-px">
              <div
                id={row.id}
                onClick={selectHandle}
                className={"flex items-center justify-left h-12 px-4 rounded-lg text-color01 hover:bg-gradient-to-r from-color03 to-color04 cursor-pointer".concat(
                  currentElement === row.id ? " bg-color03" : ""
                )}
              >
                <span className="without-selection min-w-[140px] text-left">
                  {row.name}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
