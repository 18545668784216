import { createSlice } from "@reduxjs/toolkit";

export const selectSlice = createSlice({
  name: "select",
  initialState: {
    visible: false,
    value: {
      visible: true,
      type: "",
    },
    selected: undefined,
  },
  reducers: {
    showSelect: (state, PayloadActionObject = {}) => {
      const payload = PayloadActionObject.payload;
      state.visible = true;
      state.value = { type: payload.type };
    },
    setSelected: (state, selected) => {
      state.visible = false;
      state.selected = selected;
    },
    hideSelect: (state) => {
      state.visible = false;
    },
  },
});

export const { showSelect, hideSelect, setSelected } = selectSlice.actions;

export default selectSlice.reducer;
