import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    value: {
      visible: true,
      text: "",
    },
  },
  reducers: {
    showAlert: (state, PayloadActionObject = {}) => {
      state.visible = true;
      state.value = PayloadActionObject.payload;
    },
    hideAlert: (state) => {
      state.visible = false;
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
