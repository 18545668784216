import { useTranslation } from "#hooks/lang.hook";

export function Cookies({ setCookieUsing }) {
  const translation = useTranslation([
    { group: "cookies", name: "title" },
    { group: "cookies", name: "weusecookie" },
    { group: "cookies", name: "necessary" },
    { group: "cookies", name: "thirdcompanies" },
    { group: "cookies", name: "selectit" },
    { group: "cookies", name: "accept" },
    { group: "cookies", name: "decline" },
  ]);

  return (
    <div className="flex flex-col w-full bg-color05 p-4 rounded-t-lg">
      <span className="text-xl font-semibold text-color01 without-selection mb-4">
        {translation.title}
      </span>
      <span className="text-xl font-semibold text-color02 without-selection">
        {translation.weusecookie}
      </span>

      <span className="text-xl font-semibold text-color02 without-selection">
        {translation.necessary}
      </span>

      <span className="text-xl font-semibold text-color02 without-selection">
        {translation.thirdcompanies}
      </span>

      <span className="text-xl font-semibold text-color02 without-selection">
        {translation.selectit}
      </span>

      <div className="flex justify-between mx-[2rem] my-3">
        <button
          type="button"
          className="text-color01 bg-gradient-to-r from-color03 to-color04 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-color04 shadow-lg shadow-color01/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          onClick={() => setCookieUsing("true")}
        >
          <span className="text-xl without-selection">
            {translation.accept}
          </span>
        </button>
        <button
          type="button"
          className="text-color01 bg-gradient-to-r from-color03 to-color04 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-color04 shadow-lg shadow-color01/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          onClick={() => setCookieUsing("false")}
        >
          <span className="text-xl without-selection">
            {translation.decline}
          </span>
        </button>
      </div>
    </div>
  );
}
