import { createSlice } from "@reduxjs/toolkit";

export const acceptSlice = createSlice({
  name: "accept",
  initialState: {
    visible: false,
    message: "",
    accepted: undefined,
  },
  reducers: {
    showAccept: (state, PayloadActionObject = {}) => {
      state.visible = true;
      state.message = PayloadActionObject.payload;
      state.accepted = undefined;
    },
    setAccept: (state, PayloadActionObject = {}) => {
      state.visible = false;
      state.accepted = PayloadActionObject.payload;
    },
    hideAccept: (state) => {
      state.visible = false;
    },
  },
});

export const { showAccept, hideAccept, setAccept } = acceptSlice.actions;

export default acceptSlice.reducer;
