export const formatToDeciamal = (srtNumber) => {
  srtNumber = srtNumber.toString();
  if (srtNumber === "") {
  } else if (!srtNumber.includes(".")) {
    srtNumber += ".00";
  } else if (srtNumber.split(".")[1].length === 0) {
    srtNumber += "00";
  } else if (srtNumber.split(".")[1].length === 1) {
    srtNumber += "0";
  }
  return srtNumber;
};
