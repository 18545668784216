import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import {
  useWeekNames,
  getLocalMonth,
  monthComparing,
  dateComparing,
} from "#common/dateFactory";
import {
  Accept,
  Button,
  Spinner,
  SearchGroup,
  SelectPeriod,
  SelectWorkDayType,
  WORK_DAY_DEFAULT,
  Breadcrumb,
  SelectPersonPosition,
} from "#formComponents";
import { useParams } from "react-router-dom";
import { useHttp } from "#hooks/http.hook";
import { showAccept } from "#features/acceptSlice";
import { useSelector, useDispatch } from "react-redux";
import { PicPlus, PicPeriod, PicTrashbin } from "#svg";
import { useNavigate } from "react-router-dom";

const READONLY = "readonly";

export function Tabel() {
  const { id } = useParams();
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.lang.value);
  const accepted = useSelector((state) => state.accept.accepted);
  const { loading, request } = useHttp();
  const [noneditable, setNoneditable] = useState(
    user.permissions === READONLY || false
  );
  const [currentType, setCurrentType] = useState(WORK_DAY_DEFAULT);
  const navigate = useNavigate();
  const [showPeriod, setShowPeriod] = useState(false);
  const [showPersonSelection, setShowPersonSelection] = useState(false);
  const [edited, setEdited] = useState(false);

  const [currentRow, setCurrentRow] = useState();

  const [dataDoc, setDataDoc] = useState({});
  const [people, setPeople] = useState([]);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState({ start: 0, end: 0 });
  const [isDragging, setDragging] = useState(false);

  const [textFilter, setTextFilter] = useState("");

  const [arrDays, setArrDays] = useState([]);
  const translation = useTranslation([
    { group: "menu", name: "desktop" },
    { group: "tabel", name: "employee" },
    { group: "common", name: "search" },
    { group: "common", name: "save" },
    { group: "alert", name: "acceptionDelete" },
  ]);
  const [translationDays] = useState(useWeekNames(true));

  let filteredPeople = [];

  const saveData = useCallback(
    async (id, people, data) => {
      if (people && people.length) {
        try {
          await request(
            "/tabel",
            "POST",
            {
              id,
              details: JSON.stringify({
                people,
                data,
              }),
            },
            {}
          );
          setEdited(false);
          navigate("/timesheet");
        } catch (e) {}
      }
    },
    [request]
  );

  const getAcception = useCallback(
    (textAlert) => {
      dispatch(showAccept(textAlert));
    },
    [dispatch]
  );

  const periodHandle = () => {
    setShowPeriod(true);
  };

  const hideFormHandle = () => {
    setShowPeriod(false);
  };

  const hideFormPersonHandle = () => {
    setShowPersonSelection(false);
  };

  const applyData = ({ start, end }) => {
    setPeriod({ start, end });
    setShowPeriod(false);
  };

  const addNewPerson = (newRec) => {
    setPeople((prev) => {
      const alreadyIs = prev.find(
        (row) => row.id === newRec.personId && row?.positionId === newRec.jobId
      );
      if (!alreadyIs) {
        return [
          ...prev,
          {
            id: newRec.personId,
            name: newRec.personName,
            position: newRec.jobName,
            positionId: newRec.jobId,
          },
        ];
      }
      return prev;
    });
    setEdited(true);
  };

  const selectHandle = (peopleId, positionId, date, editable) => {
    if (editable === false) {
      return;
    }
    setData((prev) => {
      setEdited(true);
      const currentRow = prev.find(
        (rowFind) =>
          rowFind.peopleId === peopleId &&
          rowFind.positionId === positionId &&
          rowFind.date === date
      );
      if (currentRow) {
        return prev.map((rowPrev) =>
          rowPrev.peopleId === peopleId &&
          rowPrev.positionId === positionId &&
          rowPrev.date === date
            ? { ...rowPrev, value: currentType }
            : rowPrev
        );
      }
      return [
        ...prev,
        {
          id: crypto.randomUUID(),
          peopleId,
          positionId,
          date,
          value: currentType,
        },
      ];
    });
  };

  const saveHandle = (e) => {
    saveData(id, people, data);
  };

  const addHandle = (e) => {
    setShowPersonSelection(true);
  };

  const getData = (peopleId, positionId, dayOfMonth) => {
    const found = data.find(
      (row) =>
        row.peopleId === peopleId &&
        row.positionId === positionId &&
        row.date === dayOfMonth
    );
    return found ? found.value : {};
  };

  const readData = useCallback(
    async (id, permissions) => {
      try {
        const result = await request(`/tabel/${id}`, "GET", "", {});
        setEdited(undefined);
        if (result?.records?.length) {
          const currentDoc = result.records[0];
          const details = currentDoc?.details;
          const dataMonth = new Date(currentDoc.month_salary);
          let firstDay = new Date(
            dataMonth.getFullYear(),
            dataMonth.getMonth(),
            1
          ).getDate();
          let lastDay = new Date(
            dataMonth.getFullYear(),
            dataMonth.getMonth() + 1,
            0
          ).getDate();
          const currentM = new Date();

          if (details.people.length) {
            setCurrentRow({
              peopleId: details.people[0].id,
              positionId: details.people[0].positionId,
            });
          }

          setDataDoc({
            dateClose: details.dateClose,
            department: currentDoc.department,
            month: getLocalMonth(dataMonth, currentLang),
            currentMonth: new Date(
              dataMonth.getFullYear(),
              dataMonth.getMonth(),
              1
            ),
          });
          if (permissions !== READONLY) {
            setNoneditable(
              details.dateClose
                ? dateComparing(
                    new Date(details.dateClose),
                    new Date(
                      dataMonth.getFullYear(),
                      dataMonth.getMonth() + 1,
                      0
                    )
                  )
                : false
            );
          }
          if (monthComparing(dataMonth, currentM)) {
            lastDay = currentM.getDate();
          }
          if (lastDay - firstDay > 8 && !permissions === READONLY) {
            firstDay = lastDay - 7;
          }
          if (monthComparing(dataMonth, new Date())) {
            setPeriod({ start: firstDay, end: lastDay });
          } else {
            setPeriod({ start: 1, end: lastDay });
          }
          setPeople(details.people || []);
          setData(details.data || []);
        } else {
          setDataDoc({});
        }
      } catch (e) {}
    },
    [request]
  );

  const moveItem = (ss, fff) => {
    // if (fff < 296) {
    //   setPeople((prev) => {
    //     const currIndex = prev.indexOf(ss);
    //     const newArray = [...prev];
    //     const [movedItem] = newArray.splice(currIndex, 1);
    //     newArray.splice(2, 0, movedItem);
    //     return newArray;
    //   });
    // }
    // console.log(ss, fff);
  };

  const deleteHandle = () => {
    if (!currentRow) return;
    const currentRowData = people.find(
      (rowFind) =>
        rowFind.id === currentRow.peopleId &&
        rowFind.positionId === currentRow.positionId
    );
    const position = currentRowData.position
      ? ` (${currentRowData.position})`
      : "";
    getAcception(
      `${translation.acceptionDelete} ${currentRowData.name}${position}?`
    );
  };

  const selectPeople = (peopleId, positionId) => {
    setCurrentRow({
      peopleId,
      positionId,
    });
  };

  useEffect(() => {
    if (accepted !== true) return;
    setPeople((prev) => {
      const newPrev = prev.filter(
        (rowFilter) =>
          !(
            rowFilter.id === currentRow.peopleId &&
            rowFilter?.positionId === currentRow?.positionId
          )
      );

      setCurrentRow(
        newPrev && newPrev.length > 1
          ? { peopleId: newPrev[0].id, positionId: newPrev[0].positionId }
          : undefined
      );

      return newPrev;
    });
    setData((prev) =>
      prev.filter(
        (rowFilter) =>
          !(
            rowFilter.id === currentRow.peopleId &&
            rowFilter?.positionId === currentRow?.positionId
          )
      )
    );
    setEdited(true);
  }, [accepted]);

  useEffect(() => {
    if (user.permissions === READONLY) setNoneditable(true);
    readData(id, user.permissions);
  }, [id, user]);

  useEffect(() => {
    if (!dataDoc.currentMonth) return;

    const endOfMonth = new Date(
      dataDoc.currentMonth.getFullYear(),
      dataDoc.currentMonth.getMonth() + 1,
      0
    );
    const newArr = [];
    for (
      let day = new Date(dataDoc.currentMonth);
      day <= endOfMonth;
      day.setDate(day.getDate() + 1)
    ) {
      const dayNumber = day.getDate();
      if (period.start <= dayNumber && period.end >= dayNumber) {
        newArr.push({
          id: dayNumber,
          day: day.toISOString(),
          dayNumber: day.getDay(),
          name: translationDays[day.getDay()],
        });
      }
    }
    setArrDays(newArr);
  }, [dataDoc, period, translationDays]);

  if (textFilter === "") {
    filteredPeople = people;
  } else {
    filteredPeople = people.filter(
      (obj) =>
        obj.name.toLowerCase().includes(textFilter.toLowerCase()) ||
        (obj?.position &&
          obj.position.toLowerCase().includes(textFilter.toLowerCase()))
    );
  }

  return (
    <div className="pt-4">
      <Accept />
      {showPeriod && (
        <SelectPeriod
          currentStart={period.start}
          currentEnd={period.end}
          hideForm={hideFormHandle}
          applyData={applyData}
        />
      )}
      {showPersonSelection && (
        <SelectPersonPosition
          hideForm={hideFormPersonHandle}
          applyData={addNewPerson}
        />
      )}
      <div className="sm:pl-[3rem] md:pl-[5rem] pr-[1rem] flex flex-col gap-3">
        <Breadcrumb
          arr={[
            { href: "/timesheet", name: translation.desktop },
            { href: "#", name: dataDoc.department },
          ]}
        />
        <div className="pl-[4rem] md:pl-[0px] flex flex-wrap items-center gap-3 ml-2">
          <span className="without-selection cursor-default">
            {dataDoc.month}
          </span>
          {!user.isTabelAdd || noneditable ? null : (
            <Button onClickHandle={addHandle} icon={<PicPlus />} size="small" />
          )}
          {!user.isTabelAdd || noneditable ? null : (
            <Button
              onClickHandle={deleteHandle}
              icon={<PicTrashbin />}
              size="small"
            />
          )}
          {noneditable ? null : (
            <Button
              onClickHandle={periodHandle}
              icon={<PicPeriod />}
              size="small"
            />
          )}
          <SearchGroup textFilter={textFilter} setTextFilter={setTextFilter} />
          {noneditable
            ? null
            : edited && (
                <Button
                  onClickHandle={saveHandle}
                  label={translation.save}
                  size="small"
                />
              )}
        </div>
        {noneditable ? null : (
          <div className="pl-[3rem] md:pl-[0px] sticky left-0 top-0 z-[11] bg-color04 w-full">
            <SelectWorkDayType
              currentType={currentType}
              setCurrentType={setCurrentType}
              idTabel={id}
            />
          </div>
        )}
        {loading === true && (
          <div className="absolute flex w-full mt-12 justify-center">
            <Spinner />
          </div>
        )}
        <div
          className={"flex overflow-auto max-w-[calc(100vw)] ".concat(
            noneditable
              ? "max-h-[calc(100svh-7rem)]"
              : "max-h-[calc(100svh-10rem)]"
          )}
        >
          <AnimatePresence>
            <motion.table className="text-left text-sm font-light">
              <thead className="border-b font-medium bg-color04">
                <tr>
                  <th
                    scope="col"
                    key={"col"}
                    className={
                      "sticky left-0 top-0 without-selection cursor-default px-2 bg-color04 z-10"
                    }
                  >
                    {translation.employee}
                  </th>
                  {arrDays.map((rowDay) => {
                    const editable = !noneditable
                      ? dataDoc.dateClose
                        ? new Date(dataDoc.dateClose) < new Date(rowDay.day)
                        : true
                      : false;
                    return (
                      <th
                        scope="col"
                        className="without-selection pb-1 sticky top-0 bg-color04"
                        key={rowDay.id}
                      >
                        <div
                          className={"flex flex-col justify-center items-center w-10".concat(
                            editable ? " text-color01" : " text-[#6c757d]"
                          )}
                        >
                          <span className="without-selection cursor-default font-small text-sm">
                            {rowDay.id}
                          </span>
                          <span className="without-selection cursor-default font-small text-xs">
                            {rowDay.name}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {filteredPeople.map((row, i) => {
                  return (
                    <motion.tr
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        duration: 0.05,
                        delay: 0.05 * filteredPeople.indexOf(row),
                      }}
                      onClick={() => selectPeople(row.id, row.positionId)}
                      className={
                        "transition duration-300 ease-in-out hover:bg-color03 h-10"
                      }
                      key={`${row.id}${row.positionId}`}
                      id={`${row.id}${row.positionId}`}
                    >
                      <td
                        className="sticky left-0 h-10 whitespace-nowrap font-medium px-2 rounded-l-md cursor-default"
                        key={`col${row.id}${row.positionId}`}
                      >
                        <motion.div
                          //animate={isDragging ? onTop : flat}
                          whileHover={noneditable ? {} : { scale: 1.03 }}
                          whileTap={noneditable ? {} : { scale: 1.12 }}
                          drag="y"
                          dragConstraints={{ top: 0, bottom: 0 }}
                          dragElastic={noneditable ? 0 : 1}
                          onDragStart={() => setDragging(true)}
                          onDragEnd={(e) => {
                            setDragging(false);
                          }}
                          // onDrop={() => {
                          //   console.log("POLUCHEN", row.id);
                          // }}
                          // onDragOver={(e) => {
                          //   e.preventDefault();
                          //   console.log("POLUCHEN", row.id);
                          // }}
                          onDrag={(e, { point }) => moveItem(row.id, point.y)}
                          className={"flex flex-col pt-0 px-3 rounded-lg shadow-lg shadow-color01/50"
                            .concat(row.position ? "" : " py-3")
                            .concat(
                              currentRow &&
                                user.isTabelAdd &&
                                currentRow.peopleId === row.id &&
                                currentRow.positionId === row.positionId
                                ? " bg-color03"
                                : " bg-color04"
                            )}
                        >
                          <span>{row.name}</span>
                          {row.position && (
                            <span className="flex self-end p-0 mt-[-0.4rem] text-[0.6rem] text-color01">
                              {row.position}
                            </span>
                          )}
                        </motion.div>
                      </td>
                      {arrDays.map((rowDay) => {
                        const currentValue = getData(
                          row.id,
                          row.positionId,
                          rowDay.id
                        );
                        const newDayDate = new Date(rowDay.day);
                        const editable = !noneditable
                          ? dataDoc.dateClose
                            ? new Date(dataDoc.dateClose) < newDayDate
                            : true
                          : false;
                        let fired = row.fired
                          ? new Date(row.fired) < newDayDate
                          : false;
                        if (row.start && fired === false) {
                          newDayDate.setHours(23, 59, 59, 999);
                          fired = new Date(row.start) > newDayDate;
                        }
                        const currentColor = currentValue?.color
                          ? currentValue?.color.charAt(0) === "#"
                            ? currentValue?.color
                            : currentValue?.color
                                .replace("bg-[", "")
                                .replace("]", "")
                          : "";

                        return (
                          <td className="table-cell" key={rowDay.id}>
                            {!fired && (
                              <motion.div
                                onClick={() =>
                                  selectHandle(
                                    row.id,
                                    row.positionId,
                                    rowDay.id,
                                    editable
                                  )
                                }
                                whileHover={editable ? { scale: 1.1 } : {}}
                                className={`flex justify-center items-center w-10 h-8 m-0.5 p-0 font-medium rounded-lg shadow-lg shadow-color01/50 `.concat(
                                  editable
                                    ? " cursor-pointer hover:brightness-75"
                                    : " cursor-default "
                                )}
                                style={{ backgroundColor: currentColor }}
                              >
                                <span className="without-selection">
                                  {currentValue?.label}
                                </span>
                              </motion.div>
                            )}
                          </td>
                        );
                      })}
                    </motion.tr>
                  );
                })}
                <tr className="h-[10px] w-full" />
              </tbody>
            </motion.table>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
