import { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHttp } from "#hooks/http.hook";
import { Spinner, SelectGroup, InputGroup, Button } from "#formComponents";
import { useTranslation } from "#hooks/lang.hook";
import { langList } from "#lang";
import { setLang } from "#features/langSlice";
import { setUser } from "#features/userSlice";
import { motion, AnimatePresence } from "framer-motion";

export function Settings() {
  const currentLang = useSelector((state) => state.lang.value);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const { loading, request } = useHttp();
  const [edited, setEdited] = useState(false);

  const language = useRef();
  const userName = useRef();
  const userPassword = useRef();
  const translation = useTranslation([
    { group: "settings", name: "fullName" },
    { group: "settings", name: "newPassword" },
    { group: "settings", name: "enterNewPassword" },
    { group: "settings", name: "language" },
    { group: "common", name: "save" },
  ]);

  const saveData = useCallback(
    async (lang, name, newPassword) => {
      try {
        await request(
          "/users",
          "POST",
          {
            name,
            newPassword,
            lang,
          },
          {}
        );
      } catch (e) {}
      dispatch(setUser({ ...user, name: name }));
    },
    [request]
  );

  const saveDataHandle = () => {
    saveData(currentLang, userName.current.value, userPassword.current.value);
    setEdited(false);
  };

  const onChangeFieldHandle = () => {
    setEdited(true);
  };

  const selectLangHandle = (e) => {
    dispatch(setLang(language.current.value));
    setEdited(true);
  };

  useEffect(() => {
    language.current.value = currentLang;
  }, [currentLang]);

  useEffect(() => {
    userName.current.value = user.name;
  }, [user]);

  useEffect(() => {
    userName.current.value = user.name;
  }, []);

  return (
    <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04">
      {loading === true && (
        <div className="absolute flex mt-12 w-full justify-center">
          <Spinner />
        </div>
      )}

      <AnimatePresence>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 0.1,
            delay: 0,
          }}
          key={"fullName"}
        >
          <InputGroup
            groupKey={"fullName"}
            label={translation.fullName}
            type={"text"}
            placeholder={translation.fullNameContract}
            refInput={userName}
            onChangeHandle={onChangeFieldHandle}
          />
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 0.1,
            delay: 0.1 * 1,
          }}
          key={"newPassword"}
        >
          <InputGroup
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.05,
              delay: 0.05 * 1,
            }}
            groupKey={"newPassword"}
            label={translation.newPassword}
            type={"password"}
            placeholder={translation.enterNewPassword}
            refInput={userPassword}
            onChangeHandle={onChangeFieldHandle}
          />
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 0.1,
            delay: 0.1 * 2,
          }}
          key={"language"}
        >
          <SelectGroup
            label={translation.language}
            data={langList}
            nameCol={"name"}
            idCol={"id"}
            refSelect={language}
            selectHandle={selectLangHandle}
          />
        </motion.div>
      </AnimatePresence>

      {edited && (
        <div className="flex my-2">
          <Button onClickHandle={saveDataHandle} label={translation.save} />
        </div>
      )}
    </div>
  );
}
