import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { useWeekNames, getLocalMonth } from "#common/dateFactory";
import {
  Button,
  Spinner,
  SelectPeriod,
  SelectWorkDayType,
} from "#formComponents";
import { useParams } from "react-router-dom";
import { useHttp } from "#hooks/http.hook";
import { useSelector } from "react-redux";
import { PicPlus, PicPeriod } from "#svg";
import { useNavigate } from "react-router-dom";

const DAY_TYPES = [
  { id: "В", label: "", color: "bg-[#8d8d8d]" },
  { id: "H", label: "H", color: "bg-[#c70218]" },
  { id: "Л", label: "Л", color: "bg-[#ff667b]" },
  { id: "У", label: "У", color: "bg-[#8c8ee2]" },
  { id: "8", label: "8", color: "bg-[#73d000]" },
];

const listPeople = [
  { id: 1, name: "Короленко Вікторія Петрівна" },
  { id: 2, name: "Владыкин Артур Вадимович" },
  { id: 3, name: "Киндеев Владислав Робертович" },
  { id: 4, name: "Милютин Иван Марселевич" },
  { id: 5, name: "Ходин Роберт Робертович" },
  { id: 6, name: "Долженков Тимофей Валентинович" },
];

export function Tabel() {
  const { id } = useParams();
  const currentLang = useSelector((state) => state.lang.value);
  const { loading, request } = useHttp();
  const [listWorkDayType, setListWorkDayType] = useState(DAY_TYPES);
  const [currentType, setCurrentType] = useState(
    DAY_TYPES.find((type) => type.id === "8")
  );
  const navigate = useNavigate();

  const [showPeriod, setShowPeriod] = useState(false);
  const [edited, setEdited] = useState(false);

  const [dataDoc, setDataDoc] = useState({});
  const [people, setPeople] = useState([]);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState({ start: 0, end: 0 });

  const [textFilter, setTextFilter] = useState("");

  const [arrDays, setArrDays] = useState([]);
  const translation = useTranslation([
    { group: "tabel", name: "employee" },
    { group: "common", name: "search" },
    { group: "common", name: "save" },
  ]);
  const [translationDays] = useState(useWeekNames(true));

  let filteredPeople = [];

  const saveData = useCallback(
    async (id, people, data) => {
      if (people && people.length) {
        try {
          await request(
            "/tabel",
            "POST",
            {
              id,
              details: JSON.stringify({
                people,
                data,
              }),
            },
            {}
          );
          setEdited(false);
          navigate("/timesheet");
        } catch (e) {}
      }
    },
    [request]
  );

  const periodHandle = () => {
    setShowPeriod(true);
  };

  const hideFormHandle = () => {
    setShowPeriod(false);
  };

  const applyData = ({ start, end }) => {
    setPeriod({ start, end });
    setShowPeriod(false);
  };

  const selectHandle = (peopleId, date) => {
    setData((prev) => {
      setEdited(true);
      const currentRow = prev.find(
        (rowFind) => rowFind.peopleId === peopleId && rowFind.date === date
      );
      if (currentRow) {
        return prev.map((rowPrev) =>
          rowPrev.peopleId === peopleId && rowPrev.date === date
            ? { ...rowPrev, value: currentType }
            : rowPrev
        );
      }
      return [
        ...prev,
        { id: crypto.randomUUID(), peopleId, date, value: currentType },
      ];
    });
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  const saveHandle = (e) => {
    saveData(id, people, data);
  };

  const addHandle = (e) => {};

  const getData = (peopleId, dayOfMonth) => {
    const found = data.find(
      (row) => row.peopleId === peopleId && row.date === dayOfMonth
    );
    return found ? found.value : {};
  };

  const readData = useCallback(
    async (id) => {
      try {
        const result = await request(`/tabel/${id}`, "GET", "", {});
        setEdited(undefined);
        if (result?.records?.length) {
          const currentDoc = result.records[0];
          const details = currentDoc?.details;
          const dataMonth = new Date(currentDoc.month_salary);
          let firstDay = new Date(
            dataMonth.getFullYear(),
            dataMonth.getMonth(),
            1
          ).getDate();
          let lastDay = new Date(
            dataMonth.getFullYear(),
            dataMonth.getMonth() + 1,
            0
          ).getDate();
          const currentM = new Date();

          setDataDoc({
            department: currentDoc.department,
            month: getLocalMonth(dataMonth, currentLang),
            currentMonth: new Date(
              dataMonth.getFullYear(),
              dataMonth.getMonth(),
              1
            ),
          });
          if (
            dataMonth.getFullYear() === currentM.getFullYear() &&
            dataMonth.getMonth() === currentM.getMonth()
          ) {
            lastDay = currentM.getDate();
          }
          if (lastDay - firstDay > 8) {
            firstDay = lastDay - 7;
          }
          setPeriod({ start: firstDay, end: lastDay });
          setPeople(details.people || []);
          setData(details.data || []);
        } else {
          setDataDoc({});
        }
      } catch (e) {}
    },
    [request]
  );

  useEffect(() => {
    readData(id);
  }, [id]);

  useEffect(() => {
    if (!dataDoc.currentMonth) return;

    const endOfMonth = new Date(
      dataDoc.currentMonth.getFullYear(),
      dataDoc.currentMonth.getMonth() + 1,
      0
    );
    const newArr = [];
    for (
      let day = new Date(dataDoc.currentMonth);
      day <= endOfMonth;
      day.setDate(day.getDate() + 1)
    ) {
      const dayNumber = day.getDate();
      if (period.start <= dayNumber && period.end >= dayNumber) {
        newArr.push({
          id: dayNumber,
          day,
          dayNumber: day.getDay(),
          name: translationDays[day.getDay()],
        });
      }
    }
    setArrDays(newArr);
  }, [dataDoc, period, translationDays]);

  if (textFilter === "") {
    filteredPeople = people;
  } else {
    filteredPeople = people.filter((obj) =>
      obj.name.toLowerCase().includes(textFilter.toLowerCase())
    );
  }

  return (
    <div>
      {showPeriod && (
        <SelectPeriod
          currentStart={period.start}
          currentEnd={period.end}
          hideForm={hideFormHandle}
          applyData={applyData}
        />
      )}
      <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col gap-3">
        <div className="flex flex-wrap items-center gap-3 mx-2">
          <span>{dataDoc.month}</span>
          <Button onClickHandle={addHandle} icon={<PicPlus />} size="small" />
          <Button
            onClickHandle={periodHandle}
            icon={<PicPeriod />}
            size="small"
          />
          <input
            type="text"
            value={textFilter}
            className="flex flex-grow min-w-[10em] border border-color05 text-color01 text-sm rounded-lg focus:ring-color03 focus:border-color03 block p-2"
            onChange={onChangeSearchHendle}
            placeholder={translation.search}
          />
          {edited && (
            <Button
              onClickHandle={saveHandle}
              label={translation.save}
              size="small"
            />
          )}
        </div>
        <div className="mx-2">
          <SelectWorkDayType
            listWorkDayType={listWorkDayType}
            setListWorkDayType={setListWorkDayType}
            currentType={currentType}
            setCurrentType={setCurrentType}
          />
        </div>
        {loading === true && (
          <div className="absolute flex w-full mt-12 justify-center">
            <Spinner />
          </div>
        )}
        <div className="flex">
          <AnimatePresence>
            <motion.table className="text-left text-sm font-light mx-2 overflow-y-auto overflow-x-hidden">
              <thead className="border-b font-medium bg-color04">
                <tr>
                  <th
                    scope="col"
                    key={"col"}
                    className="sticky left-0 top-0 without-selection px-2 bg-color04 z-10"
                  >
                    {translation.employee}
                  </th>
                  {arrDays.map((rowDay) => (
                    <th
                      scope="col"
                      className="without-selection pb-1 sticky top-0 bg-color04"
                      key={rowDay.id}
                    >
                      <div className="flex flex-col justify-center items-center w-10">
                        <span className="without-selection font-small text-sm">
                          {rowDay.id}
                        </span>
                        <span className="without-selection font-small text-xs">
                          {rowDay.name}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredPeople.map((row) => {
                  return (
                    <motion.tr
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{
                        duration: 0.05,
                        delay: 0.05 * filteredPeople.indexOf(row),
                      }}
                      className={
                        "transition duration-300 ease-in-out hover:bg-color03 h-10"
                      }
                      key={row.id}
                      id={row.id}
                    >
                      <td
                        className="sticky left-0 whitespace-nowrap font-medium px-2 rounded-l-md cursor-default"
                        key={`col${row.id}`}
                      >
                        <span className="py-1.5 px-3 rounded-lg bg-color04 shadow-lg shadow-color01/50">
                          {row.name}
                        </span>
                      </td>
                      {arrDays.map((rowDay) => {
                        const currentValue = getData(row.id, rowDay.id);
                        return (
                          <td className="table-cell" key={rowDay.id}>
                            <motion.div
                              onClick={() => selectHandle(row.id, rowDay.id)}
                              whileHover={{ scale: 1.1 }}
                              className={"flex justify-center items-center w-10 h-7 m-0.5 font-medium cursor-pointer rounded-lg shadow-lg shadow-color01/50".concat(
                                ` ${currentValue?.color} hover:brightness-75`
                              )}
                            >
                              <span className="without-selection">
                                {currentValue?.label}
                              </span>
                            </motion.div>
                          </td>
                        );
                      })}
                    </motion.tr>
                  );
                })}
              </tbody>
            </motion.table>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
