import { configureStore } from "@reduxjs/toolkit";
import userReducer from "#features/userSlice";
import langReducer from "#features/langSlice";
import alertReducer from "#features/alertSlice";
import acceptReducer from "#features/acceptSlice";
import cacheReducer from "#features/cacheSlice";
import selectReducer from "#features/selectSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    lang: langReducer,
    alert: alertReducer,
    accept: acceptReducer,
    cache: cacheReducer,
    select: selectReducer,
  },
});
