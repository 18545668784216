export const langList = [
  { id: "uk", name: "Українська", width: 120 },
  { id: "en", name: "English", width: 65 },
  { id: "ru", name: "Мова окупантів", width: 90 },
];

export const langEN = () => {
  return {
    common: {
      save: "Save",
      cancel: "Cancel",
      return: "Back",
      select: "Select",
      connect: "Connect",
      delete: "Delete",
      search: "Search...",
      makeSelection: "Make a choice",
      next: "Next:",
      day1: "Mo",
      day2: "Tu",
      day3: "We",
      day4: "Th",
      day5: "Fr",
      day6: "Sa",
      day7: "Su",
    },
    home: {
      caption: "HR helper",
      start: "Start",
    },
    auth: {
      user: "User:",
      password: "Password:",
      onemoretime: "and again:",
      start: "Start",
      passwordEnter: "Enter email",
      passwordFormat: "Email format is incorrect!",
      passwordErr: "You have entered an incorrect password!",
      serverErr: "Something wrong. No connection!",
      loginTrainer: "Trainer's login",
      newUserTrainer: "New trainer",
      loginClient: "Client's login",
      newUserClient: "New client",
      create: "Create",
      set: "Set",
      invitationSent: "Confirming invitation was sent to your email.",
      reset: "Reset password?",
      resetSent: "Reset password link was sent to your email",
      google: "Login with Google",
      applyPin: "Next",
    },
    menu: {
      desktop: "Tabel",
      salarylist: "Payroll",
      department: "Department",
      holidays: "Holidays",
      history: "History",
      database: "DB",
      exercises: "Items",
      clients: "Clients",
      payments: "Payments",
      personal: "Personal",
      job: "Jobs",
      user: "Users",
      chat: "Chat",
      settings: "Settings",
      exit: "Exit",
      daytype: "Day types",
      info: "Info",
    },
    history: {
      login: "Login",
      status: "Status",
      dateCreate: "Date created",
      unique: "Unique active",
      falsy: "Quantity falsy",
    },
    settings: {
      groupMain: "Main settings",
      groupAdditional: "Additional settings",
      groupSound: "Sound settings",
      fullNameContract: "Full name for contract",
      fullName: "Full name:",
      avaname: "Avatar name:",
      enterAvaName: "Enter avatar name",
      trainerEmail: "Trainer:",
      enterTrainerEmail: "Enter trainer's e-mail",
      pulsometr: "Connect a heart rate monitor",
      passport: "ID:",
      passportContract: "ID for contract",
      telephone: "Telephone:",
      telephoneContract: "Telephone for contract",
      language: "Language",
      audio: "Sound preferences",
      speech: "Speech recognition",
      speechOn: "On (high usage of CPU/Battary)",
      speechOff: "Off (eco usage)",
      level: "Level of sounds",
      newPassword: "Change password",
      enterNewPassword: "Enter new password",
    },
    tabel: { employee: "Employee" },
    database: {
      table: "Table",
      db: "Database",
      size: "Size",
    },
    salary: {
      approve: "Approve",
      employee: "Employee",
      department: "Department",
      position: "Position",
      date: "Date empl",
      base: "Salary base",
      approved: "Salary approved",
      result: "Difference",
      comments: "Comments",
      copy: "Copy salary",
      userApproved: "Approved",
    },
    salaryList: {
      number: "#",
      month: "Month",
      departments: "Departments",
      approved: "Approved",
      notApproved: "No",
    },
    tabelList: {
      month: "Month",
      department: "Department",
    },
    classificator: {
      id: "ID",
      name: "Name",
      id_company: "Company",

      department: "Departments",
      departmentCode: "ID",
      people: "Employee",
      peopleCode: "ID",
      peopleDepartment: "Departments",
      job: "Jobs",
      jobCode: "ID",
      daytype: "Day types",
      dayTypeId: "Id",
      dayTypeOrder: "Order",
      dayTypeShortName: "Abr",
      dayTypeColor: "Color",
      usersEmail: "Email",
      usersLang: "Lang",
      usersIs_salary_approve: "Salary",
      usersIs_tabel: "Tabel",
      usersIs_tabel_add: "TabelAdd",
      usersDisabled: "Disabled",
      usersPermissions: "Permission",
    },
    selectPeriod: {
      apply: "Apply",
      caption: "Current period",
      from: "From",
      to: "to",
    },
    payments: {
      testPeriod: "Test period",
      attention: "Attention!!!",
      testPeriodText: `During the test period, the process of setting up the service takes place.
      Possible failures and loss of your data entered into the system.`,
    },
    client: {
      fastConnection: "Fast connection",
      name: "Name:",
      nameInput: "Name of client",
      sername: "Sername:",
      sernameInput: "Sername of client",
      telephone: "Telephone:",
      telephoneInput: "Telephone of client",
      details: "Notes:",
      detailsInput: "Notes",
      emailInput: "Email of client",
    },
    listSelection: {
      select: "Apply selected",
      makeSelection: "Select exercises",
    },
    cookies: {
      title: "We value your privacy!",
      weusecookie:
        "We use cookies to help you navigate efficiently and perform certain functions.",
      necessary: `The cookies that are categorised as "Necessary" are stored on your
        browser as they are essential for enabling the basic functionalities of
        the site.`,
      thirdcompanies: `We also use third-party cookies that help us analyse how you use this
        website, store your preferences, and provide the content and
        advertisements that are relevant to you. These cookies will only be
        stored in your browser with your prior consent.`,
      selectit: `You can choose to enable or disable cookies but disabling some of them
        may affect your browsing experience.`,
      accept: "Accept",
      decline: "Decline",
    },
    alert: {
      attention: "Attention",
      yes: "Yes",
      no: "No",
      acceptionDelete: "Are you really want to delete",
    },
    error: {
      errConnection: "Connection error",
      errConnectionOther: "Connection fails",
      decline: "Call was declined",
      noUserByEmail: "There is no user with such email!",
      requiredField:
        "There are empty required fields. Check fields red underlined!",
    },
  };
};

export const langUK = () => {
  return {
    common: {
      save: "Зберігти",
      cancel: "Скасувати",
      return: "Повернутись",
      select: "Обрати",
      connect: "Підключитись",
      delete: "Видалити",
      search: "Пошук...",
      makeSelection: "Оберіть запис",
      next: "Далі:",
      day1: "Пн",
      day2: "Вт",
      day3: "Ср",
      day4: "Чт",
      day5: "Пт",
      day6: "Сб",
      day7: "Нд",
    },
    home: {
      caption: "HR helper",
      start: "Увійти",
    },
    auth: {
      user: "Користувач:",
      password: "Пароль:",
      onemoretime: "ще раз:",
      start: "Почати",
      passwordEnter: "Введіть email",
      passwordFormat: "Не правильний формат електроної пошти!",
      passwordErr: "Ви ввели не правильний пароль!",
      serverErr: "Щось пішло не так. Проблема зв'язку з сервером!",
      loginTrainer: "Авторизація тренера",
      newUserTrainer: "Новий тренер",
      loginClient: "Авторизація клієнта",
      newUserClient: "Новий клієнт",
      create: "Створити",
      set: "Встановити",
      invitationSent:
        "На Вашу пошту було відправлено лист для підтвердження електронної адреси.",
      reset: "Відновити пароль?",
      resetSent: "На Вашу пошту було відправлено інструкцію по скиданню пароля",
      google: "Увійти з Google",
      applyPin: "Далі",
    },
    menu: {
      desktop: "Табелі",
      salarylist: "Відомості",
      department: "Підрозділи",
      holidays: "Свята",
      history: "Історія",
      database: "База даних",
      exercises: "Довідники",
      clients: "Клієнти",
      payments: "Оплати",
      personal: "Персонал",
      job: "Посади",
      user: "Користувачі",
      chat: "Чат",
      settings: "Налаштування",
      exit: "Вийти",
      daytype: "Типи днів",
      info: "Інформація",
    },
    history: {
      login: "Логін",
      status: "Статус",
      dateCreate: "Дата створення",
      unique: "Унікальні активні",
      falsy: "Кількість проблем",
    },
    settings: {
      groupMain: "Основні налаштування",
      groupAdditional: "Додаткові налаштування",
      groupSound: "Налаштування звуку",
      fullNameContract: "ПІБ для договору",
      fullName: "ПІБ:",
      avaname: "Ініціали аватару:",
      enterAvaName: "Введіть ініціали аватару",
      trainerEmail: "Тренер:",
      enterTrainerEmail: "вкажіть e-mail тренера",
      pulsometr: "Підключити пульсометр",
      passport: "ID:",
      passportContract: "Паспортні дані для договора",
      telephone: "Контактний телефон:",
      telephoneContract: "Контактний телефон для договора",
      language: "Мова",
      audio: "Звуковий вивід",
      speech: "Розпізнавання мови",
      speechOn: "Включено (підвищене використання Процесора/Батареї)",
      speechOff: "Виключено (Заощадливе використання)",
      level: "Рівень сигналів",
      newPassword: "Змінити пароль",
      enterNewPassword: "Введіть новий пароль",
    },
    tabel: { employee: "Співробітник" },
    database: {
      table: "Таблиця",
      db: "База",
      size: "Розмір",
    },
    salary: {
      approve: "Затвердити",
      employee: "ПІБ",
      department: "Підрозділ",
      position: "Посада",
      date: "Дата прийому",
      base: "ЗП на руки",
      approved: "ЗП затверджена",
      result: "Різниця",
      comments: "Примітки",
      copy: "Скопіювати ЗП",
      userApproved: "Затверджено",
    },
    salaryList: {
      number: "№",
      month: "Місяць",
      departments: "Департаменти",
      approved: "Затверджено",
      notApproved: "Ні",
    },
    tabelList: {
      month: "Місяць",
      department: "Департамент",
    },
    classificator: {
      id: "ID",
      name: "Назва",
      id_company: "Компанія",

      department: "Департаменти",
      departmentCode: "Код",
      people: "Співробітники",
      peopleCode: "Код",
      peopleDepartment: "Департамент",
      job: "Посади",
      jobCode: "Код",
      daytype: "Типи днів",
      dayTypeId: "Id",
      dayTypeOrder: "Порядок",
      dayTypeShortName: "Скор",
      dayTypeColor: "Колір",
      usersEmail: "Email",
      usersLang: "Мова",
      usersIs_salary_approve: "Зарплата",
      usersIs_tabel: "Табелі",
      usersIs_tabel_add: "Додавання людей",
      usersDisabled: "Не активовано",
      usersPermissions: "Права",
    },
    selectPeriod: {
      apply: "Прийняти",
      caption: "Поточний період",
      from: "Від",
      to: "до",
    },
    payments: {
      testPeriod: "Тестовий період",
      attention: "Увага!!!",
      testPeriodText: `В підчас тестового періоду відбувається процес налаштування сервісу.
      Можливі збої та втрата Ваших даних, що було введено в систему.`,
    },
    client: {
      fastConnection: "Швидке підключення",
      name: "Ім'я:",
      nameInput: "Ім'я клієнта",
      sername: "Прізвище:",
      sernameInput: "Прізвище клієнта",
      telephone: "Контактний телефон:",
      telephoneInput: "Телефон клієнта",
      details: "Додатково:",
      detailsInput: "Нотатки",
      emailInput: "Електронна пошта",
    },
    listSelection: {
      select: "Зберігти",
      makeSelection: "Оберіть вправи",
    },
    cookies: {
      title: "Ми цінуємо Вашу приватність!",
      weusecookie:
        "Ми використовуємо кукі для того щоб забезпечити ефективність навігації по сайту.",
      necessary: `Кукі, що категоризовані як "Обов'язкові" зберігаються в Вашому браузері для забезпечання базової функціональності сайту.`,
      thirdcompanies: `Ми також використовуємо кукі третіх сторін, які допомагають нам аналізувати, як ви користуєтеся цим 
      веб-сайтом, зберігати Ваші налаштування та надавати вміст і
      релевантні для вас рекламні оголошення. Ці файли cookie будуть 
      зберігаються тільки у Вашому браузері за Вашою попередньою згодою.`,
      selectit: `Ви можете ввімкнути або вимкнути кукі, але вимкання деяких 
      може вплинути на зручність використання сайту.`,
      accept: "Прийняти",
      decline: "Скасувати",
    },
    alert: {
      attention: "Увага",
      yes: "Так",
      no: "Ні",
      acceptionDelete: "Ви дійсно хочете видалити",
    },
    error: {
      errConnection: "З'єднання несподівано завершено",
      errConnectionOther: "З'єднання розірвано",
      decline: "Абонент відхилив дзвінок",
      noUserByEmail: "Користувача з такою электронною поштою не знайдено!",
      requiredField:
        "Існують не заповнені обовя'зкові поля. Перевірте поля, що підкреслено червоним!",
    },
  };
};

export const langRU = () => {
  return {
    common: {
      save: "Сохранить",
      cancel: "Отмена",
      return: "Назад",
      select: "Выбрать",
      connect: "Подключиться",
      delete: "Удалить",
      search: "Поиск...",
      makeSelection: "Сделайте выбор",
      next: "Далее:",
      day1: "Пн",
      day2: "Вт",
      day3: "Ср",
      day4: "Чт",
      day5: "Пт",
      day6: "Сб",
      day7: "Вс",
    },
    home: {
      caption: "HR helper",
      start: "Войти",
    },
    auth: {
      user: "Пользователь:",
      password: "Пароль:",
      onemoretime: "еще раз:",
      start: "Почати",
      passwordEnter: "Введіть email",
      passwordFormat: "Не правильный формат электронной почты!",
      passwordErr: "Вы ввели не правильный пароль!",
      serverErr: "Что-то пошло не так. Отсутствует связь с сервером!",
      loginTrainer: "Авторизация тренера",
      newUserTrainer: "Новый тренер",
      loginClient: "Авторизация клиента",
      newUserClient: "Новый клиент",
      create: "Создать",
      set: "Установить",
      invitationSent:
        "На Вашу почту отправлено письмо для подтверждения электронного адреса.",
      reset: "Восстановить пароль?",
      resetSent: "На Вашу почту отправлена инструкция для сброса пароля",
      google: "Войти с Google",
      applyPin: "Далее",
    },
    menu: {
      desktop: "Табели",
      salarylist: "Ведомости",
      department: "Подразделения",
      holidays: "Праздники",
      history: "История",
      database: "База данных",
      exercises: "Справочники",
      clients: "Клиенты",
      payments: "Оплаты",
      personal: "Персонал",
      job: "Должности",
      user: "Пользователи",
      chat: "Чат",
      settings: "Настройки",
      exit: "Выйти",
      daytype: "Типы дней",
      info: "Информация",
    },
    history: {
      login: "Логин",
      status: "Статус",
      dateCreate: "Дата создания",
      unique: "Уникальные активные",
      falsy: "Количество проблем",
    },
    settings: {
      groupMain: "Основные настройки",
      groupAdditional: "Дополнительные настройки",
      groupSound: "Настройка звука",
      fullNameContract: "ФИО для договора",
      fullName: "ФИО:",
      avaname: "Инициалы аватара:",
      enterAvaName: "Введите инициалы аватара",
      passport: "Паспортні дані:",
      passportContract: "Паспортні дані для договора",
      trainerEmail: "Тренер:",
      enterTrainerEmail: "Введите e-mail тренера",
      pulsometr: "Подключить пульсометр",
      telephone: "Контактний телефон:",
      telephoneContract: "Контактний телефон для договора",
      language: "Язык",
      audio: "Звуковые настройки",
      speech: "Распознавание команд",
      speechOn: "Включено (повышенное использование Процесора/Батареи)",
      speechOff: "Выключено (Энергосбережение)",
      level: "Уровень сигналов",
      newPassword: "Изменить пароль",
      enterNewPassword: "Введите новый пароль",
    },
    tabel: { employee: "Сотрудник" },
    database: {
      table: "Таблица",
      db: "База",
      size: "Размер",
    },
    salary: {
      approve: "Утвердить",
      employee: "ФИО",
      department: "Подразделение",
      position: "Должность",
      date: "Дата приема",
      base: "ЗП на руки",
      approved: "ЗП утвержд",
      result: "Разница",
      comments: "Примечание",
      copy: "Копировать ЗП",
      userApproved: "Утверждено",
    },
    salaryList: {
      number: "№",
      month: "Месяц",
      departments: "Департаменты",
      approved: "Утверждено",
      notApproved: "Нет",
    },
    tabelList: {
      month: "Месяц",
      department: "Департамент",
    },
    classificator: {
      id: "ID",
      name: "Наименование",
      id_company: "Компания",
      department: "Департаменты",
      departmentCode: "Код",
      people: "Сотрудники",
      peopleCode: "Код",
      peopleDepartment: "Департамент",
      job: "Должности",
      jobCode: "Код",
      daytype: "Типы дней",
      dayTypeId: "Id",
      dayTypeOrder: "Порядок",
      dayTypeShortName: "Сокр",
      dayTypeColor: "Цвет",
      usersEmail: "Email",
      usersLang: "Язык",
      usersIs_salary_approve: "Зарплата",
      usersIs_tabel: "Табели",
      usersIs_tabel_add: "Добавление людей",
      usersDisabled: "Не активен",
      usersPermissions: "Права",
    },
    selectPeriod: {
      apply: "Принять",
      caption: "Текущий период",
      from: "От",
      to: "до",
    },
    payments: {
      testPeriod: "Тестовый период",
      attention: "Внимание!!!",
      testPeriodText: `В период тестирования происходит процесс настрройки сервиса.
      Возможны сбои и полная потеря Ваших данных введенных в систему.`,
    },
    client: {
      fastConnection: "Быстрое подключение",
      name: "Имя:",
      nameInput: "Имя клиента",
      sername: "Фамилия:",
      sernameInput: "Фамилия клиента",
      telephone: "Контактный телефон:",
      telephoneInput: "Телефон клиента",
      details: "Дополнительно:",
      detailsInput: "Записи",
      emailInput: "Электронная почта",
    },
    listSelection: {
      select: "Сохранить",
      makeSelection: "Выберите упражнения",
    },
    cookies: {
      title: "We value your privacy!",
      weusecookie:
        "We use cookies to help you navigate efficiently and perform certain functions.",
      necessary: `The cookies that are categorised as "Necessary" are stored on your
        browser as they are essential for enabling the basic functionalities of
        the site.`,
      thirdcompanies: `We also use third-party cookies that help us analyse how you use this
        website, store your preferences, and provide the content and
        advertisements that are relevant to you. These cookies will only be
        stored in your browser with your prior consent.`,
      selectit: `You can choose to enable or disable cookies but disabling some of them
        may affect your browsing experience.`,
      accept: "Accept",
      decline: "Decline",
    },
    alert: {
      attention: "Внимание",
      yes: "Да",
      no: "Нет",
      acceptionDelete: "Вы действительно хотите удалить",
    },
    error: {
      errConnection: "Соединение внезапно завершилось",
      errConnectionOther: "Соединение разорвано",
      decline: "Абонент отклонил звонок",
      noUserByEmail: "Пользователя с такой электронной почтой не было найдено!",
      requiredField:
        "Существуют не заполненные обязательные поля. Проверьте поля, которые подчеркнуты красным!",
    },
  };
};
