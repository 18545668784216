export function Logo() {
  return (
    <div className="flex flex-col cursor-default">
      <div className="flex border border-color01 h-[0.6rem] w-full bg-color01 rounded-md mb-[-1.8rem]"></div>
      <div className="flex justify-between mx-2 mb-1">
        <div className="flex gap-[1px]">
          <div className="h-[2.4rem] mt-[0.3rem] w-[0.8rem] border border-4 border-color02 bg-color02 shadow-lg shadow-color01/50 rounded-md" />
          <div className="h-[3rem] w-[0.8rem] border border-4 border-color02 bg-color02 shadow-lg shadow-color01/50 rounded-md" />
          <div className="h-[3rem] w-[0.8rem] border border-4 border-color02 bg-color02 shadow-lg shadow-color01/50 rounded-md" />
        </div>
        <div className="flex gap-[1px]">
          <div className="h-[3rem] w-[0.8rem] border border-4 border-color02 bg-color02 shadow-lg shadow-color01/50 rounded-md" />
          <div className="h-[3rem] w-[0.8rem] border border-4 border-color02 bg-color02 shadow-lg shadow-color01/50 rounded-md" />
          <div className="h-[2.4rem] mt-[0.3rem] w-[0.8rem] border border-4 border-color02 bg-color02 shadow-lg shadow-color01/50 rounded-md" />
        </div>
      </div>

      <div
        className="flex items-center justify-center border-y border-y-2 border-color01"
        role="status"
      >
        <span
          className="text-3xl font-semibold text-color02 without-selection"
          // style={{ textShadow: "gray -1px +1px 0.2em" }}
        >
          toolFIT
        </span>
        <span
          className="text-3xl font-bold text-color01 without-selection"
          // style={{ textShadow: "gray -1px +2px 0.2em" }}
        >
          .online
        </span>
      </div>
    </div>
  );
}
