export function SelectGroup({
  label,
  data,
  nameCol,
  idCol,
  refSelect,
  selectHandle,
}) {
  return (
    <div className="flex flex-col my-2">
      <label
        htmlFor="input_select"
        className="flex text-left items-center mx-2 text-sm font-medium text-color01 min-w-[100px] without-selection"
      >
        {label}
      </label>
      <select
        id="input_select"
        type="text"
        ref={refSelect}
        className="border border-color02 text-color01 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        required
        onChange={selectHandle}
      >
        {data.map((row) => (
          <option
            key={row[idCol]}
            value={row[idCol]}
            className="flex-1 h-12 py-4 without-selection min-w-[140px] text-left text-xl"
          >
            {row[nameCol]}
          </option>
        ))}
      </select>
    </div>
  );
}
