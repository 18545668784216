import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      id: "unknown",
      name: "-",
      permissions: "-",
      isSalaryApprove: false,
      isTabel: false,
      email: "unknown",
    },
  },
  reducers: {
    setUser: (state, PayloadActionObject = {}) => {
      state.value = PayloadActionObject.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
