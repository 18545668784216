import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { useHttp } from "#hooks/http.hook";
import { Spinner, Button } from "#formComponents";

export function Database() {
  const [listDocs, setListDocs] = useState();
  const { loading, request } = useHttp();

  const readData = useCallback(async () => {
    try {
      const result = await request("/database", "GET", "", {});
      setListDocs(!result.records ? [] : result.records);
    } catch (e) {}
  }, [request]);

  const runQueries = useCallback(async () => {
    try {
      await request("/database", "POST", {}, {});
    } catch (e) {}
  }, [request]);

  const translation = useTranslation([
    { group: "database", name: "table" },
    { group: "database", name: "db" },
    { group: "database", name: "size" },
  ]);

  const updateHandle = (e) => {
    runQueries();
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <div className="pt-10 pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04">
      {loading === true && (
        <div className="absolute flex w-full mt-14 justify-center">
          <Spinner />
        </div>
      )}
      <Button
        onClickHandle={updateHandle}
        label={"Обновить таблицы"}
        size="small"
      />
      <AnimatePresence>
        <motion.table className="text-left text-sm font-light overflow-y-auto overflow-x-hidden">
          <thead className="sticky top-0 border-b font-medium bg-color04">
            <tr>
              <th scope="col" key={"month"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.table}
                </span>
              </th>
              <th scope="col" key={"size"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.size}
                </span>
              </th>
              <th scope="col" key={"quantity"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.size}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {listDocs &&
              listDocs.tablesize.map((row, id) => {
                return (
                  <motion.tr
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.05,
                      delay: 0.05 * id,
                    }}
                    className={
                      "transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-default"
                    }
                    key={id}
                    id={id}
                  >
                    <td className="whitespace-nowrap font-medium px-2 rounded-l-md">
                      {row.table_name}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.total_size}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.row_count}
                    </td>
                  </motion.tr>
                );
              })}
          </tbody>
        </motion.table>
      </AnimatePresence>

      <AnimatePresence>
        <motion.table className="text-left text-sm font-light overflow-y-auto overflow-x-hidden">
          <thead className="sticky top-0 border-b font-medium bg-color04">
            <tr>
              <th scope="col" key={"month"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.db}
                </span>
              </th>
              <th scope="col" key={"department"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.size}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {listDocs &&
              listDocs.dbsize.map((row, id) => {
                return (
                  <motion.tr
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.05,
                      delay: 0.05 * id,
                    }}
                    className={
                      "transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-default"
                    }
                    key={id}
                    id={id}
                  >
                    <td className="whitespace-nowrap font-medium px-2 rounded-l-md">
                      {row.database_name}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.size}
                    </td>
                  </motion.tr>
                );
              })}
          </tbody>
        </motion.table>
      </AnimatePresence>
    </div>
  );
}
