import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "#hooks/lang.hook";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useHttp } from "#hooks/http.hook";
import { Spinner } from "#formComponents";
import { useSelector } from "react-redux";
import { getLocalMonth } from "#common/dateFactory";

export function TabelList() {
  const currentLang = useSelector((state) => state.lang.value);
  const navigate = useNavigate();
  const [listDocs, setListDocs] = useState();
  const { loading, request } = useHttp();

  const readData = useCallback(async () => {
    try {
      const result = await request("/tabel", "GET", "", {});
      setListDocs(!result.records ? [] : result.records);
    } catch (e) {}
  }, [request]);

  const translation = useTranslation([
    { group: "tabelList", name: "month" },
    { group: "tabelList", name: "department" },
  ]);

  const selectHandle = (e) => {
    const key = e.target.id || e.target.parentElement.id;
    navigate(`/timesheet/${key}`);
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <div className="pl-[5rem] pr-[1rem] min-h-[calc(100svh)] flex flex-col bg-color04">
      {loading === true && (
        <div className="absolute flex w-full mt-14 justify-center">
          <Spinner />
        </div>
      )}
      <AnimatePresence>
        <motion.table className="text-left text-sm font-light overflow-y-auto overflow-x-hidden">
          <thead className="sticky top-0 border-b font-medium bg-color04">
            <tr>
              <th scope="col" key={"month"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.month}
                </span>
              </th>
              <th scope="col" key={"department"} className="px-2 pt-4 pb-1">
                <span className="without-selection cursor-default">
                  {translation.department}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {listDocs &&
              listDocs.map((row) => {
                return (
                  <motion.tr
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                      duration: 0.05,
                      delay: 0.05 * listDocs.indexOf(row),
                    }}
                    className={
                      "transition duration-300 ease-in-out hover:bg-color03 h-10 cursor-pointer"
                    }
                    key={row.id}
                    id={row.id}
                    onClick={selectHandle}
                  >
                    <td className="whitespace-nowrap font-medium px-2 rounded-l-md">
                      {getLocalMonth(new Date(row.month_salary), currentLang)}
                    </td>
                    <td className="whitespace-nowrap font-medium px-2 rounded-r-md">
                      {row.department}
                    </td>
                  </motion.tr>
                );
              })}
          </tbody>
        </motion.table>
      </AnimatePresence>
    </div>
  );
}
