import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "#hooks/lang.hook";
import { hideAlert } from "#features/alertSlice";

export function Alert() {
  const dispatch = useDispatch();
  const currentAlert = useSelector((state) => state.alert.value);
  const currentAlertvisible = useSelector((state) => state.alert.visible);
  const translation = useTranslation([{ group: "alert", name: "attention" }]);

  const hideAlertHandle = () => {
    dispatch(hideAlert());
  };

  if (!currentAlertvisible) return <></>;

  return (
    <div
      className="absolute w-full min-h-[calc(100svh)] flex items-center justify-center z-50"
      onClick={hideAlertHandle}
    >
      <div className="absolute flex w-[75%] h-[50%] min-h-[20rem] bg-color01 opacity-90 rounded-lg shadow-lg shadow-color01/50" />
      <div className="absolute flex flex-col w-[75%] h-[50%] min-h-[20rem] items-center justify-around">
        <div className="flex flex-1">
          <span className="text-color05 text-4xl font-bold italic mt-12 without-selection cursor-default">
            {translation.attention}
          </span>
        </div>
        <div className="flex flex-1">
          <span className="text-color05 text-2xl font-semibold mt-4 without-selection cursor-default">
            {currentAlert}
          </span>
        </div>
      </div>
    </div>
  );
}
