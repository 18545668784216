import { createSlice } from "@reduxjs/toolkit";

export const audioSlice = createSlice({
  name: "audio",
  initialState: {
    value: "default",
  },
  reducers: {
    setAudio: (state, PayloadActionObject = "") => {
      state.value = PayloadActionObject.payload;
    },
  },
});

export const { setAudio } = audioSlice.actions;

export default audioSlice.reducer;
