export function PicSecType({ strokeWidth, fill }) {
  const stroke = strokeWidth || "1";
  const colorFill = fill || "blue";

  return (
    <svg
      aria-hidden="true"
      className="w-12 h-12"
      height="50px"
      width="50px"
      fill={colorFill}
      stroke="white"
      strokeWidth={stroke}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
