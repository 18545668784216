export const classificatorSettings = (type) => {
  const settings = {
    people: {
      classificatorName: "people",
      tableName: "people",
      editInList: true,
      columns: [
        {
          id: "name",
          name: "name",
          label: "peopleName",
          type: "string",
          required: true,
        },
        {
          id: "departmentName",
          name: "departmentName",
          label: "peopleDepartment",
          type: "class",
          typeClass: "Department",
          isAdditional: true,
        },
        { id: "code", name: "code", label: "peopleCode", type: "string" },
      ],
    },
    department: {
      classificatorName: "department",
      tableName: "department",
      editInList: true,
      columns: [
        {
          id: "name",
          name: "name",
          label: "departmentName",
          type: "string",
          required: true,
        },
        { id: "code", name: "code", label: "departmentCode", type: "string" },
      ],
    },
    holiday: {
      classificatorName: "holiday",
      tableName: "holidays",
      editInList: true,
      columns: [
        { id: "date", name: "date", label: "holidayDate" },
        { id: "name", name: "name", label: "holidayName" },
      ],
    },
    daytype: {
      classificatorName: "daytype",
      tableName: "daytype",
      editInList: true,
      columns: [
        {
          id: "shortname",
          name: "shortname",
          label: "dayTypeShortName",
          type: "string",
          required: true,
        },
        {
          id: "name",
          name: "name",
          label: "dayTypeName",
          type: "string",
          required: true,
        },
      ],
    },
  };

  return settings[type];
};
