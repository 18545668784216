export { Authentificator } from "#components/Authentificator";
export { History } from "#components/History";
export { Database } from "#components/Database";
export { Layout } from "#components/Layout";
export { Tabel } from "#components/Tabel";
export { Salary } from "#components/Salary";
export { SalaryList } from "#components/SalaryList";
export { Settings } from "#components/Settings";
export { TabelList } from "#components/TabelList";
export { Classificator } from "#components/Classificator";
