import { useState, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHttp } from "#hooks/http.hook";
import { Spinner } from "#formComponents";
import { setCache } from "#features/cacheSlice";
import { hideSelect, setSelected } from "#features/selectSlice";
import { useTranslation } from "#hooks/lang.hook";
import { PicClose } from "#svg";

export function FormSelect() {
  const widthMenu = useSelector((state) => state.widthMenu.value);
  const cache = useSelector((state) => state.cache.value);
  const type = useSelector((state) => state.select.value.type);
  const currentSelectVisible = useSelector((state) => state.select.visible);

  const { loading, request } = useHttp();
  const [textFilter, setTextFilter] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const searchField = useRef();
  const dispatch = useDispatch();
  const translation = useTranslation([
    { group: "common", name: "makeSelection" },
    { group: "classificator", name: "name" },
  ]);
  let listForView = [];

  const readData = useCallback(
    async (cachedData) => {
      if (cachedData && cachedData.length) {
        setCurrentList(cachedData);
        return;
      }

      try {
        const result = await request("/" + type, "GET", "", {});
        setCurrentList(!result.records ? [] : result.records);
      } catch (e) {}
    },
    [request, type]
  );

  useEffect(() => {
    readData(cache[type]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    searchField.current && searchField.current.focus();
  }, []);

  useEffect(() => {
    dispatch(setCache({ [type]: currentList }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentList]);

  const onSelectHendle = (e) => {
    const key = e.target.id || e.target.parentElement.id;
    const seclectedRow = currentList.find((row) => row.id === key);
    dispatch(setSelected({ ...seclectedRow, type }));
  };

  const closeHandle = (e) => {
    dispatch(hideSelect());
  };

  const onChangeSearchHendle = (e) => {
    setTextFilter(e.target.value);
  };

  if (!currentSelectVisible) return <></>;

  if (textFilter === "") {
    listForView = currentList;
  } else {
    listForView = currentList.filter((obj) =>
      obj.name.toLowerCase().includes(textFilter.toLowerCase())
    );
  }

  return (
    <div
      className={`z-10 mx-3 absolute max-h-[calc(100vh-13.74rem)] flex self-center justify-self-center`}
      style={{ width: `calc(92vw - ${widthMenu}px)` }}
    >
      <div className="flex flex-col w-full pb-8 pt-4 bg-color04 rounded-lg shadow-lg shadow-color01/50">
        {loading === true && (
          <div className="absolute flex w-full mt-12 justify-center">
            <Spinner />
          </div>
        )}
        <div className="flex flex my-2 mx-4 justify-between">
          <span className="text-lg font-medium text-color01 without-selection">
            {translation.makeSelection}
          </span>
          <div
            onClick={closeHandle}
            className="fill-color02 h-6 w-6 text-colorRed transition-{rotate} duration-150 hover:rotate-180"
          >
            <PicClose />
          </div>
        </div>
        <div className="px-4">
          <input
            type="text"
            ref={searchField}
            value={textFilter}
            className="border border-color02 text-color01 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            onChange={onChangeSearchHendle}
            placeholder="Пошук..."
          />
        </div>
        <div className="px-4">
          <ul className="w-full divide-y divide-gray-200 mt-6 border-b max-h-[30rem] overflow-x-auto">
            <li className="pb-3 sm:pb-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-semibold text-gray-900 truncate without-selection">
                    {translation.name}
                  </p>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900"></div>
              </div>
            </li>
            {listForView.map((row) => (
              <li
                className="py-3 sm:py-4 bg-color03 hover:bg-gradient-to-br cursor-pointer hover:border"
                key={row.id}
                id={row.id}
                onClick={onSelectHendle}
              >
                <span className="flex text-sm text-color05 font-semibold truncate text-left px-2 without-selection">
                  {row.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
