import { createSlice } from "@reduxjs/toolkit";

export const cacheSlice = createSlice({
  name: "cache",
  initialState: {
    value: {
      gymnastics: [],
      inventar: [],
      taskTypes: [],
      taskGroups: [],
      ritm: [
        {
          value: [],
        },
      ],
    },
  },
  reducers: {
    setCache: (state, PayloadActionObject = {}) => {
      state.value = { ...state.value, ...PayloadActionObject.payload };
    },
    setCacheSubTable: (state, PayloadActionObject = {}) => {
      const clientId = PayloadActionObject.payload.clientId;
      const field = PayloadActionObject.payload.field;
      const value = PayloadActionObject.payload.value;
      const valueBefore = state.value[field].filter(
        (row) => row.client !== clientId
      );
      console.log("state.value", { ...state.value });
      state.value = {
        ...state.value,
        [field]: [...valueBefore, ...value],
      };
    },
    removeCache: (state, PayloadActionObject = {}) => {
      state.value[PayloadActionObject.payload] = [];
    },
  },
});

export const { setCache, setCacheSubTable, removeCache } = cacheSlice.actions;

export default cacheSlice.reducer;
