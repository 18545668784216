import { useMemo } from "react";
import { useSelector } from "react-redux";
import { langEN, langUK, langRU } from "#lang";

export const useTranslation = (names) => {
  const currentLang = useSelector((state) => state.lang.value);
  return useMemo(
    () => translationByLang(currentLang, names),
    [currentLang, names]
  );
};

const translationByLang = (currentLang, names) => {
  if (!names?.length) return;

  const translation = {};
  names.forEach((row) => {
    switch (currentLang) {
      case "en":
        translation[row.name] = langEN()[row.group][row.name];
        break;
      case "uk":
        translation[row.name] = langUK()[row.group][row.name];
        break;
      case "ru":
        translation[row.name] = langRU()[row.group][row.name];
        break;
      default:
        translation[row.name] = langEN()[row.group][row.name];
    }
  });
  return translation;
};
