import { useState, useEffect } from "react";
import { PicClose } from "#svg";
import { Button } from "#formComponents";
import { useTranslation } from "#hooks/lang.hook";

export function SelectPeriod({
  currentStart,
  currentEnd,
  maxDay = 31,
  hideForm,
  applyData,
}) {
  const [newStart, setNewStart] = useState(currentStart);
  const [newEnd, setNewEnd] = useState(currentEnd);

  const translation = useTranslation([
    { group: "selectPeriod", name: "apply" },
    { group: "selectPeriod", name: "caption" },
    { group: "selectPeriod", name: "from" },
    { group: "selectPeriod", name: "to" },
  ]);

  const verifyDate = (value) => {
    let val = value;
    val = val.replace(/[^0-9]/g, "");

    if (val > maxDay) {
      val = maxDay;
    }

    if (val <= 0 || val === "") {
      val = 1;
    }
    return Number(val);
  };

  const changeStartHandle = (e) => {
    setNewStart(verifyDate(e.target.value));
  };

  const changeEndHandle = (e) => {
    setNewEnd(verifyDate(e.target.value));
  };

  const periodHandle = (e) => {
    applyData({ start: newStart, end: newEnd });
  };

  useEffect(() => {
    if (newStart > newEnd) setNewEnd(newStart);
  }, [newStart]);

  useEffect(() => {
    if (newStart > newEnd) setNewStart(newEnd);
  }, [newEnd]);

  return (
    <div className="absolute w-full min-h-[calc(100svh)] flex items-center justify-center z-50">
      <div className="absolute flex w-[75%] min-h-[20rem] bg-color02 opacity-90 rounded-lg shadow-lg shadow-color01/50" />
      <div className="absolute flex flex-col w-[75%] min-h-[20rem] items-center">
        <div className="flex w-full justify-end">
          <div
            onClick={hideForm}
            className="fill-color01 mt-4 mr-4 h-6 w-6 text-colorRed transition-{rotate} duration-150 hover:rotate-180"
          >
            <PicClose />
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center gap-4">
          <div className="flex">
            <span className="text-color05 text-2xl font-bold italic without-selection cursor-default">
              {translation.caption}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <span className="flex items-center justify-center h-14 text-color05 text-2xl font-bold italic without-selection cursor-default">
              {translation.from}
            </span>
            <input
              type="number"
              onChange={changeStartHandle}
              // onKeyDown={onKeyDown}
              value={newStart}
              className="h-14 border border-color02 text-color01 font-bold text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-center"
              max={31}
              min={1}
            />
            <span className="flex items-center justify-center h-14 text-color05 text-2xl font-bold italic without-selection cursor-default">
              {translation.to}
            </span>
            <input
              type="number"
              value={newEnd}
              onChange={changeEndHandle}
              // onKeyDown={onKeyDown}
              className="h-14 border border-color02 text-color01 font-bold text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 text-center"
              max={maxDay}
              min={1}
            />
          </div>
          <Button
            onClickHandle={periodHandle}
            label={translation.apply}
            size="small"
          />
        </div>
      </div>
    </div>
  );
}
