import { useState } from "react";
import { motion } from "framer-motion";
import { DeleteGroup } from "#formComponents";
import { PicClose } from "#svg";

export function FormEdit({
  type,
  closeHandle,
  deleteHandle,
  openedDetails,
  startCalling,
}) {
  const [showDelete, setShowDelete] = useState(true);

  const deleteTopHandle = () => {
    deleteHandle(openedDetails);
  };

  return (
    <motion.div
      initial={{ opacity: 0, width: "100%" }}
      animate={{ opacity: 1, width: "100%" }}
      transition={{
        duration: 0.4,
      }}
      className={`absolute z-95 flex flex-col flex-1 self-center justify-self-center pb-8 pt-4 bg-color05 rounded-lg shadow-lg shadow-color01/50`}
    >
      <div
        className={"flex flex my-2 mx-4".concat(
          deleteHandle && showDelete ? " justify-between" : " justify-end"
        )}
      >
        {deleteHandle && showDelete && (
          <DeleteGroup deleteHandle={deleteTopHandle} />
        )}
        <div
          onClick={closeHandle}
          className="fill-color02 h-6 w-6 text-colorRed transition-{rotate} duration-150 hover:rotate-180"
        >
          <PicClose />
        </div>
      </div>
      <div className="flex grow mx-4"></div>
    </motion.div>
  );
}
