import { useState, useEffect } from "react";
import { PicTrashbin } from "#svg";
import { useTranslation } from "#hooks/lang.hook";
import { motion } from "framer-motion";

export function DeleteGroup({
  deleteHandle,
  setTopDeletion,
  transparentButton = true,
  leftMenu = false,
}) {
  const [deletion, setDeletion] = useState(false);
  const translation = useTranslation([
    { group: "common", name: "cancel" },
    { group: "common", name: "delete" },
  ]);

  const startDeleteHandle = () => {
    setDeletion(true);
  };

  const cancelHandle = () => {
    setDeletion(false);
  };

  const acceptHandle = () => {
    setDeletion(false);
    deleteHandle();
  };

  useEffect(() => {
    if (setTopDeletion) setTopDeletion(deletion);
  }, [deletion]);

  return (
    <>
      {deletion ? (
        <div className="relative">
          <div
            className={"absolute mb-4 flex items-center justify-center z-10".concat(
              leftMenu ? " ms-[-12rem]" : ""
            )}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{
                duration: 0.1,
              }}
              className="inline-flex rounded-md transition duration-150 ease-in-out shadow-lg shadow-color01/50"
              role="group"
            >
              <button
                type="button"
                className="without-selection hover:bg-color04 inline-block rounded-l bg-color05 border px-6 pb-2 pt-2.5 text-xs font-medium text-colorRed transition duration-150 ease-in-out"
                onClick={cancelHandle}
              >
                {translation.cancel}
              </button>
              <button
                type="button"
                className="without-selection inline-block rounded-r bg-color05 border px-6 pb-2 pt-2.5 text-xs font-medium text-colorRed transition duration-150 ease-in-out hover:text-color05 hover:bg-colorRed"
                onClick={acceptHandle}
              >
                {translation.delete}
              </button>
            </motion.div>
          </div>
        </div>
      ) : transparentButton ? (
        <div className="cursor-pointer" onClick={startDeleteHandle}>
          <PicTrashbin fillcolors={true} />
        </div>
      ) : (
        <button
          type="button"
          className="me-2 text-color01 bg-color05 hover:bg-color04 focus:ring-4 focus:outline-none focus:ring-color04 shadow-lg shadow-color01/50 font-medium rounded-lg text-sm px-5 py-1.5 text-center cursor-pointer"
          onClick={startDeleteHandle}
        >
          <PicTrashbin fillcolors={true} />
        </button>
      )}
    </>
  );
}
