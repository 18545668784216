import { createSlice } from "@reduxjs/toolkit";

export const langSlice = createSlice({
  name: "lang",
  initialState: {
    value: window.localStorage.getItem("lang"),
  },
  reducers: {
    setLang: (state, PayloadActionObject = "") => {
      state.value = PayloadActionObject.payload;
    },
  },
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
