export function PicClient() {
  return (
    <svg
      fill="#036280"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 460.16 460.16"
    >
      <g id="XMLID_328_">
        <path
          id="XMLID_330_"
          d="M321.137,226.403l-29.642-56.813c-2.633-5.431-7.292-8.675-12.216-11.529l-47.704-27.182l0.34-63.578
		l51.523-41.241c6.309-5.051,7.33-14.261,2.279-20.571c-5.049-6.309-14.258-7.332-20.57-2.279l-56.974,45.605
		c-3.448,2.759-5.465,6.929-5.489,11.346l-0.43,80.337l-62.176,109.12c-1.283,2.736-2.716,4.665-2.716,9.894v181.132
		c0,10.777,8.736,19.512,19.512,19.512c10.777,0,19.512-8.736,19.512-19.512V277.318l8.108,4.619l22.001,161.344
		c1.457,10.686,11.302,18.153,21.969,16.697c10.677-1.456,18.153-11.292,16.697-21.969l-21.785-159.76l8.861-15.552l78.692-15.155
		c4.54-0.875,8.4-3.843,10.411-8.007C323.351,235.371,323.276,230.502,321.137,226.403z M251.316,229.217l19.981-35.068
		l14.801,28.369L251.316,229.217z"
        />
        <circle id="XMLID_329_" cx="274.293" cy="101.481" r="33.864" />
      </g>
    </svg>
  );
}
