import { useState, useEffect } from "react";
import {
  MenuSalary,
  MenuSettings,
  MenuClassificators,
  MenuLogoff,
  MenuErrorReport,
  MenuUsers,
  MenuDayType,
  MenuHistory,
  MenuClients,
  MenuSchedule,
} from "#svg/menu";
import { useTranslation } from "#hooks/lang.hook";
import { Outlet, useNavigate } from "react-router-dom";
import { SideMenu, Spinner } from "#formComponents";
import { useSelector, useDispatch } from "react-redux";
import { useHttp } from "#hooks/http.hook";
import { setUser } from "#features/userSlice";
import { setLang } from "#features/langSlice";

const ADMIN = "admin";

export const Layout = () => {
  const currentLang = useSelector((state) => state.lang.value);
  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, request } = useHttp();

  const translation = useTranslation([
    { group: "menu", name: "desktop" },
    { group: "menu", name: "salarylist" },
    { group: "menu", name: "department" },
    { group: "menu", name: "holidays" },
    { group: "menu", name: "history" },
    { group: "menu", name: "database" },
    { group: "menu", name: "personal" },
    { group: "menu", name: "job" },
    { group: "menu", name: "user" },
    { group: "menu", name: "daytype" },
    { group: "menu", name: "settings" },
    { group: "menu", name: "exit" },
  ]);

  const getMenuData = (curUser) => {
    return [
      ...(curUser.isSalaryApprove || curUser.permissions === ADMIN
        ? [
            {
              id: "/payroll",
              name: translation.salarylist,
              pic: <MenuSalary />,
            },
          ]
        : []),
      ...(curUser.isTabel || curUser.permissions === ADMIN
        ? [
            {
              id: "/timesheet",
              name: translation.desktop,
              pic: <MenuSchedule />,
            },
          ]
        : []),
      ...(curUser.permissions === ADMIN
        ? [
            {
              id: "/history",
              name: translation.history,
              pic: <MenuHistory />,
            },
            {
              id: "/database",
              name: translation.database,
              pic: <MenuErrorReport />,
            },
            {
              id: "/people",
              name: translation.personal,
              pic: <MenuClients />,
            },
            {
              id: "/job",
              name: translation.job,
              pic: <MenuClassificators />,
            },
            {
              id: "/users",
              name: translation.user,
              pic: <MenuUsers />,
            },
            {
              id: "/daytype",
              name: translation.daytype,
              pic: <MenuDayType />,
            },
          ]
        : []),

      {
        id: "/settings",
        name: translation.settings,
        pic: <MenuSettings />,
      },
      {
        id: "/",
        name: translation.exit,
        pic: <MenuLogoff />,
      },
    ];
  };

  const [currentSection, setCurrentSection] = useState("salary");
  const [menuData, setMenuData] = useState(getMenuData(user));

  const menuSelectHendle = (selected) => {
    if (selected === "/") {
      window.localStorage.removeItem("token");
    }
    setCurrentSection(selected);
    navigate(selected);
  };

  const checkToken = async () => {
    const strRes = await request("/users/checkToken", "POST", {}, {});
    if (strRes?.data) {
      if (strRes.data.lang) {
        window.localStorage.setItem("lang", strRes.data.lang);
        dispatch(setLang(strRes.data.lang));
      }
      dispatch(
        setUser({
          email: strRes.email,
          id: strRes.data.id,
          name: strRes.data.name,
          permissions: strRes.data.permissions,
          isSalaryApprove: strRes.data.isSalaryApprove,
          isTabel: strRes.data.isTabel,
          isTabelAdd: strRes.data.isTabelAdd,
        })
      );
      setMenuData(
        getMenuData({
          permissions: strRes.data.permissions,
          isSalaryApprove: strRes.data.isSalaryApprove,
          isTabel: strRes.data.isTabel,
        })
      );
    }
  };

  useEffect(() => {
    setMenuData(getMenuData(user));
  }, [currentLang]);

  useEffect(() => {
    const curToken = window.localStorage.getItem("token");
    if (curToken) checkToken();
  }, []);

  return (
    <div className="flex flex-col bg-color04">
      {loading === true && (
        <div className="absolute flex mt-14 w-[300px] items-center justify-center">
          <Spinner />
        </div>
      )}
      {menuData?.length && (
        <SideMenu
          menuData={menuData}
          currentSection={currentSection}
          menuSelectHendle={menuSelectHendle}
          currentLang={currentLang}
        />
      )}
      <span className="absolute right-0 top-2 without-selection cursor-default bg-color04 px-[1rem] font-bold justify-end text-color02">
        {user.name}
      </span>
      <main>
        <Outlet />
      </main>
    </div>
  );
};
