import { useState } from "react";
import { motion } from "framer-motion";
import { langList } from "#lang";

export function SideMenu({
  menuData,
  currentSection,
  menuSelectHendle,
  currentLang,
}) {
  const [isHovered, setHovered] = useState(false);

  const selectMenuHandle = (e) => {
    const selected =
      e.target.id ||
      e.target.parentElement.id ||
      e.target.parentElement.parentElement.id ||
      e.target.parentElement.parentElement.parentElement.id;
    menuSelectHendle(selected);
  };

  const marginLeft = `-${
    langList.find((row) => row.id === currentLang).width
  }px`;

  return (
    <motion.div
      className="absolute flex flex-col flex-1 max-w-xs px-2 my-4 bg-color02 rounded-r-lg z-[9000]"
      onHoverStart={(e) => {
        setHovered(true);
      }}
      onHoverEnd={(e) => {
        setHovered(false);
      }}
    >
      {menuData && (
        <ul className="flex flex-col my-12">
          {menuData.map((menu) => (
            <li className="my-px group relative" key={menu.id}>
              <motion.div
                whileTap={{ scale: 0.85 }}
                id={menu.id}
                onClick={selectMenuHandle}
                className={"flex flex-row items-center h-12 px-4 rounded-lg font-bold hover:bg-color03 hover:text-color01 cursor-pointer".concat(
                  currentSection === menu.id ? " text-color01" : " text-color05"
                )}
              >
                <span className={"flex items-center justify-center text-lg"}>
                  {menu.pic}
                </span>
                <motion.span
                  initial={{
                    opacity: 0,
                    marginLeft: marginLeft,
                  }}
                  animate={{
                    opacity: isHovered ? 1 : 0,
                    marginLeft: isHovered ? "10px" : marginLeft,
                    transition: {
                      type: "spring",
                      stiffness: 150,
                      width: 0.8,
                      opacity: isHovered
                        ? { delay: 0.2, duration: 0.1 }
                        : { duration: 0.1 },
                      margin: 1,
                    },
                  }}
                  className="without-selection"
                >
                  {menu.name}
                </motion.span>
              </motion.div>
            </li>
          ))}
        </ul>
      )}
    </motion.div>
  );
}
