import { useState, useEffect } from "react";
import { langList } from "#lang";
import { PicLang } from "#svg";
import { useDispatch } from "react-redux";
import { setLang } from "#features/langSlice";

export function LangMenu() {
  const dispatch = useDispatch();
  const [menuLangOpen, setMenuLangOpen] = useState(false);
  const selectLangHandle = () => {
    setMenuLangOpen((prev) => !prev);
  };

  const selectHandle = (e) => {
    const selected =
      e.target.id ||
      e.target.parentElement.id ||
      e.target.parentElement.parentElement.id;
    window.localStorage.setItem("lang", selected);
    dispatch(setLang(selected));
  };

  useEffect(() => {
    dispatch(setLang(window.localStorage.getItem("lang") || "en"));
  }, []);

  return (
    <div>
      <div className="absolute flex justify-end top-0 w-full text-color01 hover:text-color02">
        <div className="w-12 h-12 mx-4 mt-6" onClick={selectLangHandle}>
          <PicLang />
        </div>
      </div>
      {menuLangOpen && (
        <div
          className="absolute w-full min-h-[calc(100svh)]"
          onClick={selectLangHandle}
        >
          <ul
            className="absolute right-4 z-50 flex-col bg-color05 rounded-md shadow-sm flex flex-wrap px-3 py-4 mt-12 shadow-lg shadow-color01/50"
            role="group"
          >
            {langList.map((row) => (
              <li key={row.id} className="flex-1 my-px" onClick={selectHandle}>
                <div
                  id={row.id}
                  className="flex items-center justify-left h-12 px-4 rounded-lg text-color01 hover:bg-color03 cursor-pointer"
                >
                  <span className="without-selection min-w-[140px] text-left">
                    {row.name}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
