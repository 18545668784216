export function InputGroup({
  groupKey,
  label,
  type,
  placeholder,
  refInput,
  onChangeHandle,
  onKeyDown,
  disabled = false,
  maxlength = undefined,
}) {
  return (
    <div key={groupKey} className="flex flex-col my-2">
      <label
        htmlFor={`input_field_${groupKey}`}
        className="flex text-left items-center mx-2 mb-0.5 text-sm font-medium text-color01 without-selection"
      >
        {label}
      </label>
      <input
        id={`input_field_${groupKey}`}
        type={type}
        ref={refInput}
        onChange={onChangeHandle}
        onKeyDown={onKeyDown}
        className="border border-color02 text-color01 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
        placeholder={placeholder}
        required
        disabled={disabled}
        maxLength={maxlength}
      />
    </div>
  );
}
