import { useSelector } from "react-redux";
import {
  getFirstDayOfNextMonth,
  getFirstDayOfPreviousMonth,
  getLocalMonth,
} from "#common/dateFactory";
import { PicMinus, PicPlus } from "#svg";
import { Button } from "#formComponents";

export function SelectMonth({ currentMonth, setCurrentMonth }) {
  const currentLang = useSelector((state) => state.lang.value);
  const PrevMonthHandle = (e) => {
    setCurrentMonth((prev) => getFirstDayOfPreviousMonth(prev));
  };

  const NextMonthHandle = (e) => {
    setCurrentMonth((prev) => getFirstDayOfNextMonth(prev));
  };

  return (
    <div className="flex items-center gap-3">
      <Button
        onClickHandle={PrevMonthHandle}
        icon={<PicMinus />}
        size="small"
      />
      <span className="text-nowrap">
        {getLocalMonth(currentMonth, currentLang)}
      </span>
      <Button onClickHandle={NextMonthHandle} icon={<PicPlus />} size="small" />
    </div>
  );
}
